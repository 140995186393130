import {
    TextInput,
    Message,
    Button,
    Typography,
    FloatButton,
    DatePicker,
  } from '../../utils/antd-components/index.tsx';
  import { Form, Select } from 'antd';
  import { useState, useEffect } from 'react';
  import { useNavigate } from 'react-router-dom';
  import { ApiUtils } from '../../utils/api.jsx';
  import { useTranslation } from 'react-i18next';
  import PATHS from '../../utils/constants/Paths.tsx';
  import METHOD_TYPES from '../../utils/constants/MethodTypes.tsx';
  import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
  import routesnkt from '../../routes/routes.json';
  import TitleAndBackComponent from '../../utils/UiComponents/titleAndBackComponent.tsx';
  import dayjs from 'dayjs';
  import { useForm } from 'antd/es/form/Form';
  
  const CreateRequest = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const authUser = useAuthUser();
    const userId = authUser._id;
  
    const [form] = useForm();
    const [successMessage, setSuccessMessage] = useState(null);
    const [publishError, setPublishError] = useState(null);
    const [loading, setLoading] = useState(false);
  
    const handleSubmit = async (values) => {
      setLoading(true);
      console.log("Received values of form:", values); // Debugging
  
      try {
        if (!userId) {
          console.error("User ID is missing!");
          setPublishError("User authentication error. Please log in again.");
          return;
        }
  
        let formDataToSend = {
          ...values,
          start_date: dayjs(values.start_date).format('YYYY-MM-DD'),
          end_date: dayjs(values.end_date).format('YYYY-MM-DD'),
          userId: userId, // Ensure it's not undefined
        };
  
        console.log("Form data being sent:", formDataToSend); // Debugging
  
        const URL = PATHS.REQUEST.ADD;
        const data = await ApiUtils(URL, formDataToSend, METHOD_TYPES.POST);
        console.log("API Response:", data);
  
        if (data.status !== 200) {
          setPublishError(data.message);
          return;
        }
  
        setSuccessMessage(data.response.message);
        setPublishError(null);
        navigate(routesnkt.request);
      } catch (error) {
        console.error("Submission error:", error);
        setPublishError("Something went wrong, please try again.");
      } finally {
        setLoading(false);
      }
    };
  
    return (
      <div className="p-3 max-w-3xl mx-auto min-h-screen">
        <FloatButton.BackTop />
        <TitleAndBackComponent title={t('Create a Leave Request')} goBackText={t('back')} />
  
        <Form form={form} initialValues={{ date: dayjs() }} onFinish={handleSubmit}>
          {/* Request Type */}
          <div className="w-full flex flex-col gap-2">
            <Typography value={t('Request Type')} />
            <Form.Item name="request_type" rules={[{ required: true, message: t('Request type is required') }]}>
              <Select placeholder={t('Select')}>
                <Select.Option value="Sick">Sick</Select.Option>
                <Select.Option value="Casual">Casual</Select.Option>
                <Select.Option value="Annual">Annual</Select.Option>
              </Select>
            </Form.Item>
          </div>
  
          {/* Start Date */}
          <div className="w-full flex flex-col gap-2">
            <Typography value={t('Start Date')} />
            <Form.Item
              name="start_date"
              rules={[{ required: true, message: t('Start date is required') }]}
            >
              <DatePicker
                placeholder="Select Date"
                format="YYYY-MM-DD"
                className="h-10 w-full"
                onChange={(date) => form.setFieldValue("start_date", dayjs(date).format("YYYY-MM-DD"))}
                disabledDate={(current) => current && current < dayjs().startOf('day')} // Disable past dates
              />
            </Form.Item>
          </div>
  
          {/* End Date */}
          <div className="w-full flex flex-col gap-2">
            <Typography value={t('End Date')} />
            <Form.Item
              name="end_date"
              dependencies={['start_date']}
              rules={[
                { required: true, message: t('End date is required') },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || dayjs(value).isAfter(getFieldValue('start_date'))) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t('End date must be after start date')));
                  },
                }),
              ]}
            >
              <DatePicker
                placeholder="Select Date"
                format="YYYY-MM-DD"
                className="h-10 w-full"
                onChange={(date) => form.setFieldValue("end_date", dayjs(date).format("YYYY-MM-DD"))}
                disabledDate={(current) => current && current < dayjs().startOf('day')} // Disable past dates
              />
            </Form.Item>
          </div>
  
          {/* Duration */}
          <div className="w-full flex flex-col gap-2">
            <Typography value={t('Duration')} />
            <Form.Item
              name="duration"
              rules={[
                { required: true, message: t('Duration is required') },
                {
                  validator: (_, value) =>
                    value > 0 ? Promise.resolve() : Promise.reject(new Error(t('Duration must be a positive number'))),
                },
              ]}
            >
              <TextInput
                type="number"
                placeholder={t('Duration')}
                min="1"
                onKeyPress={(event) => {
                  if (!/^[0-9]$/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </Form.Item>
          </div>
  
          {/* Reason */}
          <div className="w-full flex flex-col gap-2">
            <Typography value={t('Reason')} />
            <Form.Item name="reason" rules={[{ required: true, message: t('Leave reason is required') }]}>
              <TextInput placeholder={t('Reason')} />
            </Form.Item>
          </div>
  
          {/* Submit Button */}
          <div className="flex justify-end">
            <Button type="primary" htmlType="submit" loading={loading} disabled={loading}>
              {t('Submit Request')}
            </Button>
          </div>
        </Form>
  
        {/* Error & Success Messages */}
        {publishError && <Message successMessage={publishError} type="error" />}
        {successMessage && <Message successMessage={successMessage} type="success" />}
      </div>
    );
  };
  
  export default CreateRequest;
  