import {
  Select,
  TextInput,
  Message,
  Button,
  RadioGroup,
  Typography,
  FloatButton,
} from '../../utils/antd-components/index.tsx';
import { Form } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useState, useEffect } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { useNavigate } from 'react-router-dom';
import { ApiUtils } from '../../utils/api.jsx';
import { useTranslation } from 'react-i18next';
// import { PATHS, METHOD_TYPES } from '../../utils/constants/index.js';
import PATHS from '../../utils/constants/Paths.tsx';
import METHOD_TYPES from '../../utils/constants/MethodTypes.tsx';
// import UploadImages from '../utils/UiComponents/commonUpload.jsx';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import routesnkt from '../../routes/routes.json';
import TitleAndBackComponent from '../../utils/UiComponents/titleAndBackComponent.tsx';

const CreateProjects = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  // const { postId } = useParams();

  // const { RangePicker } = DatePicker;
  const authUser = useAuthUser();
  const roles = authUser.rolesType;
  const [successMessage, setsuccessMessage] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    content: '',
    status: 1,
  });
  const [publishError, setPublishError] = useState(null);
  const [usersList, setUsersList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [mainProjectsList, setMainProjectsList] = useState(null);

  useEffect(() => {
    try {
      const fetchUsers = async () => {
        const URL = PATHS.USER.GET + '?status=1&limit=1000';
        const data = await ApiUtils(URL);
        if (data.status !== 200) {
          console.log(data.message);
          setPublishError(data.message);
          return;
        } else {
          setPublishError(null);
          // const filteredUsers = data.users.filter(
          //   (user) => user.roleId?.type === 'Project Manager',
          // );
          // setUsersList(filteredUsers);
          setUsersList(data.users);
        }
      };

      fetchUsers();
    } catch (error) {
      console.log(error.message);
    }
  }, []);
  useEffect(() => {
    try {
      fetchMainProjects();
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  const fetchMainProjects = async () => {
    const URL = PATHS.PROJECT.GET + '?status=1&limit=1000';
    const data = await ApiUtils(URL);
    if (data.status !== 200) {
      console.log(data.message);
      setPublishError(data.message);
      return;
    } else {
      setPublishError(null);
      setMainProjectsList(data.response);
    }
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    // e.preventDefault();
    console.log('Received values of form: ', e);
    try {
      const payload = {
        ...e,
        status: formData.status,
      };
      if (roles?.includes('admin') || roles?.includes('ProjectManager')) {
        const URL = PATHS.SUBPROJECT.ADD;
        const data = await ApiUtils(URL, payload, METHOD_TYPES.POST);
        setLoading(true);

        if (data.status !== 200) {
          setPublishError(data.message);
          return;
        } else {
          setsuccessMessage(data.response.messsage);
          setPublishError(null);
          navigate(routesnkt.subProjects);
        }
      }
      setLoading(false);
    } catch (error) {
      setPublishError(t('something-went-wrong'));
    }
  };

  const handleRadioChange = (e) => {
    const statusValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      status: statusValue,
    }));
  };

  return (
    <div className="p-3 max-w-3xl mx-auto min-h-screen">
      <FloatButton.BackTop />
      <TitleAndBackComponent
        title={t('addSubProject')}
        goBackText={t('back')}
      />
      <Form onFinish={handleSubmit}>
        <div className="flex flex-row gap-4 justify-between">
          <div className="w-full flex flex-col gap-2">
            <Typography value={t('selectaMainProject')} />
            <Form.Item name="mainprojectId" rules={[{ required: true }]}>
              <Select
                className={'h-10'}
                required={true}
                onChange={(value) => {
                  setFormData({ mainprojectId: value });
                }}
                selectedValue={formData.mainprojectId}
                options={mainProjectsList}
                placeholder={t('selectaUser')}
                label={'name'}
                id={'_id'}
              />
            </Form.Item>
          </div>
          <div className="w-full flex flex-col gap-2">
            <Typography value={t('sprojectname')} />
            <Form.Item name="name" rules={[{ required: true }]}>
              <TextInput placeholder={t('sprojectname')} />
            </Form.Item>
          </div>
          <div className="w-full flex flex-col gap-2">
            <Typography value={t('selectaPm')} />
            <Form.Item name="projectLead" rules={[{ required: true }]}>
              <Select
                className={'h-10'}
                required={true}
                options={usersList}
                placeholder={t('selectaUser')}
                label={'first_name'}
              />
            </Form.Item>
          </div>
        </div>

        <div className="flex flex-row gap-4 justify-between">
          <div className="w-full flex flex-col gap-2">
            <Typography value={t('projectCode')} />
            <Form.Item name="projectCode" rules={[{ required: true }]}>
              <TextInput placeholder="SSPT,SMT" />
            </Form.Item>
          </div>
          <div className="w-full flex flex-col gap-2">
            <Typography value={t('client-name')} />
            <Form.Item name="clientName" rules={[{ required: true }]}>
              <TextInput placeholder={t('client-name')} />
            </Form.Item>
          </div>
        </div>
        {/* <div className="w-full flex flex-col gap-2"> */}
        {/* <Typography value={t('uploadImage')} /> */}
        {/* <UploadImages formData={formData} setFormData={setFormData} /> */}
        {/* </div> */}

        {/* <div className="flex flex-col gap-4 sm:flex-col justify-between">
          <Typography value={t('select-date')} />
          <Form.Item name="dateRange" rules={[{ required: true }]}>
            <RangePicker
              className="w-full h-10"
              options={{
                ...DatePickerOptions,
                minDate: new Date(),
              }}
            />
          </Form.Item>
        </div> */}
        <div className="flex flex-row gap-4 sm:flex-col justify-between ">
          <Typography value={t('select-status')} />
          <Form.Item value="status" rules={[{ required: true }]}>
            <RadioGroup
              onChange={handleRadioChange}
              selectedValue={formData.status}
              t={t}
            />
          </Form.Item>
        </div>
        <div className="flex flex-col gap-4 sm:flex-col justify-between">
          <Typography value={t('desc')} />
          <Form.Item name="content">
            <ReactQuill
              theme="snow"
              placeholder={t('writeSomething')}
              className="h-40 mb-12"
            />
          </Form.Item>
        </div>
        <div className="flex justify-end">
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={loading}
          >
            {t('createProject')}
          </Button>
        </div>
      </Form>
      {publishError && <Message successMessage={publishError} type="error" />}
      {successMessage && (
        <Message successMessage={successMessage} type="success" />
      )}
    </div>
  );
};
export default CreateProjects;
