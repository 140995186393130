/*
 * session 1: Fore Noon, 2: After Noon, 3: All Session
 * status 1: Present, 2: Absent
 * type 1: Casual Leave ,2:Permissions, 3: Sick Leave, 4:  Marriage Leave, 5: Work From Home
 * time is for Permissions
 */
import {
  Table,
  FloatButton,
  Typography,
  // Select,
  DatePicker,
  Tooltip,
  Button,
} from '../../../utils/antd-components/index.tsx';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApiUtils } from '../../../utils/api.jsx';
import PATHS from '../../../utils/constants/Paths.tsx';
import METHOD_TYPES from '../../../utils/constants/MethodTypes.tsx';
import { useTranslation } from 'react-i18next';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { FaCheck, FaSun, FaHome } from 'react-icons/fa';
import { ImCross } from 'react-icons/im';
import { BsFillCloudSunFill } from 'react-icons/bs';
import { IoWarning } from 'react-icons/io5';
import { AiOutlineExport } from 'react-icons/ai';
import { saveAs } from 'file-saver'; // Import file-saver for file download
import * as XLSX from 'xlsx'; // Import xlsx for Excel file creation

export default function DashUsers() {
  const { t } = useTranslation();
  dayjs.extend(isBetween);
  dayjs.extend(isSameOrBefore);
  dayjs.extend(isSameOrAfter);
  const authUser = useAuthUser();
  const roles = authUser.rolesType;
  const navigate = useNavigate();
  const [pageCount, setPageCount] = useState(1);
  const [attendance, setAttendance] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [holidaysList, setHolidaysList] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [usersId, setUserId] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(dayjs());

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (roles?.includes('admin')) {
      fetchAttendanceList(searchParams);
      fetchUsersList();
      fetchHolidaysList();
    }
  }, [location.search]);

  const fetchHolidaysList = async () => {
    try {
      setLoading(true);
      const URL = PATHS.HOLIDAY.GET + `?status=all`;
      const data = await ApiUtils(URL, '', METHOD_TYPES.GET);
      if (data.status === 200) {
        setHolidaysList(data.response);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log('err in dash config', error.message);
    }
  };

  const fetchAttendanceList = async () => {
    try {
      setLoading(true);
      const URL = `${PATHS.ATTENDANCE.GET}?status=2`;
      const data = await ApiUtils(URL, '', METHOD_TYPES.GET);
      if (data.status === 200) {
        setAttendance(data.filteredResponse);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };

  const fetchUsersList = async () => {
    try {
      setLoading(true);
      const URL = PATHS.USER.GET + `?limit=1000&sort=first_name&order=asc`;
      const data = await ApiUtils(URL, '', METHOD_TYPES.GET);
      if (data.status === 200) {
        setUsersList(data.users);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };

  const handleStatusFilterChange = (pagination, filters) => {
    const statusFilterValue = filters.status && filters.status[0];
    const searchParams = new URLSearchParams(location.search);
    setPageCount(pagination.current);
    if (statusFilterValue) {
      updateURL({
        ...Object.fromEntries(searchParams),
        status: statusFilterValue,
        limit: pagination.pageSize,
        page: pagination.current,
      });
    } else {
      updateURL({
        ...Object.fromEntries(searchParams),
        status: 'all',
        limit: pagination.pageSize,
        page: pagination.current,
      });
    }
  };

  // const selectUsers = (selectedList) => {
  //   const searchParams = new URLSearchParams(location.search);
  //   if (selectedList) {
  //     setUserId(selectedList);
  //     updateURL({
  //       ...Object.fromEntries(searchParams),
  //       userId: selectedList,
  //     });
  //   } else {
  //     updateURL({
  //       ...Object.fromEntries(searchParams),
  //       userId: '',
  //     });
  //   }
  // };

  const handleDateRangeChange = (date) => {
    if (date) {
      const startOfMonth = dayjs(date).startOf('month');
      const endOfMonth = dayjs(date).endOf('month');
      setSelectedMonth(date);
      updateURL({
        startDate: startOfMonth.format('DD-MM-YYYY'),
        endDate: endOfMonth.format('DD-MM-YYYY'),
      });
    } else {
      setSelectedMonth(dayjs());
      updateURL({
        startDate: '',
        endDate: '',
      });
    }
  };

  // const disabledDate = (current) => {
  //   const currentDate = new Date();
  //   const startMonth = new Date(currentDate.getFullYear(), 6);

  //   return (
  //     (current && current.toDate() > currentDate) ||
  //     current.toDate() < startMonth
  //   );
  // };

  const updateURL = (params) => {
    navigate(`?${new URLSearchParams(params).toString()}`);
  };

  const generateDaysColumns = () => {
    const daysInMonth = dayjs(selectedMonth).daysInMonth();
    const startDate = dayjs('2024-06-01'); // Start date for attendance tracking

    const holidayMap = holidaysList.reduce((map, holiday) => {
      const holidayDate = dayjs(holiday.date).format('YYYY-MM-DD');
      map[holidayDate] = holiday.name;
      return map;
    }, {});

    const workingSaturdays = ['2024-06-29'];

    const daysColumns = [];

    for (let day = 1; day <= daysInMonth; day++) {
      const currentDate = dayjs(selectedMonth).date(day);
      const dayOfWeek = currentDate.format('ddd');
      const formattedDate = currentDate.format('YYYY-MM-DD');

      daysColumns.push({
        title: (
          <div style={{ textAlign: 'center' }}>
            {day}
            <br />
            {dayOfWeek}
          </div>
        ),
        dataIndex: `day${day}`,
        key: `day${day}`,
        render: (_, record) => {
          if (currentDate.isBefore(startDate)) {
            return null;
          }

          if (holidayMap[formattedDate]) {
            return (
              <span style={{ color: '#02B0D3' }}>
                {holidayMap[formattedDate]}
              </span>
            );
          }

          if (
            (dayOfWeek === 'Sat' &&
              !workingSaturdays.includes(formattedDate)) ||
            dayOfWeek === 'Sun'
          ) {
            return <span style={{ color: 'orange' }}>{t('holiday')}</span>;
          }

          // if (currentDate.isAfter(dayjs())) {
          //   return null;
          // }

          const dayAttendance = attendance.find(
            (att) =>
              att?.userId?._id == record._id &&
              dayjs(att.startDate).isSameOrBefore(currentDate, 'day') &&
              dayjs(att.endDate).isSameOrAfter(currentDate, 'day'),
          );

          const dayStatus = dayAttendance ? dayAttendance.status : null;

          if (dayStatus === 2) {
            return (
              <Tooltip
                title={
                  dayAttendance.type === 1
                    ? 'Casual Leave'
                    : dayAttendance.type === 2
                      ? 'Permission'
                      : dayAttendance.type === 3
                        ? 'Sick Leave'
                        : dayAttendance.type === 4
                          ? 'Marriage Leave'
                          : dayAttendance.type === 5
                            ? 'Work From Home'
                            : dayAttendance.type === 6
                              ? 'Maternity Leave'
                              : dayAttendance.type === 7
                                ? 'Paternity Leave'
                                : 'Absent'
                }
                text={dayAttendance.reason}
              >
                {dayAttendance.session === 1 &&
                dayAttendance.type !== 2 &&
                dayAttendance.type !== 7 &&
                dayAttendance.type !== 5 ? (
                  <FaSun style={{ color: 'orange', fontSize: 20 }} />
                ) : (dayAttendance.session === 2 ||
                    dayAttendance.session === 1) &&
                  dayAttendance.type === 2 &&
                  dayAttendance.type !== 8 ? (
                  <FaCheck style={{ color: 'blue', fontSize: 20 }} />
                ) : dayAttendance.session === 2 &&
                  dayAttendance.type !== 2 &&
                  dayAttendance.type !== 7 &&
                  dayAttendance.type !== 5 ? (
                  <BsFillCloudSunFill
                    style={{ color: 'magenta', height: '18px', width: '18px' }}
                  />
                ) : dayAttendance.type === 5 && dayAttendance.session === 1 ? (
                  <FaHome
                    style={{ color: 'orange', height: '18px', width: '18px' }}
                  />
                ) : dayAttendance.type === 5 && dayAttendance.session === 2 ? (
                  <FaHome
                    style={{ color: 'blue', height: '18px', width: '18px' }}
                  />
                ) : dayAttendance.type === 5 && dayAttendance.session === 3 ? (
                  <FaHome
                    style={{ color: 'lime', height: '18px', width: '18px' }}
                  />
                ) : dayAttendance.type === 8 ? (
                  <IoWarning
                    style={{ color: 'red', height: '18px', width: '18px' }}
                  />
                ) : (
                  <ImCross style={{ color: 'red' }} />
                )}
              </Tooltip>
            );
          } else {
            if (currentDate.isAfter(dayjs())) {
              return null;
            }
            return <FaCheck style={{ color: 'green' }} />;
          }
        },
      });
    }

    return daysColumns;
  };

  const handleExport = () => {
    const exportData = usersList.map((user) => {
      const summary = calculateSummaryColumns(user);
      const userAttendance = {
        'Employee Name': user.username || user.firstName,
        'Working Days': summary.workingDays,
        'Days Present': summary.daysPresent,
        'Days Absent': summary.daysAbsentInMonth,
      };

      const daysInMonth = dayjs(selectedMonth).daysInMonth();
      for (let day = 1; day <= daysInMonth; day++) {
        const currentDate = dayjs(selectedMonth).date(day);
        const formattedDate = currentDate.format('DD-MM-YYYY');

        // Find if there's an attendance record covering the current day
        const dayAttendance = attendance.find((att) => {
          if (att?.userId?._id == user._id) {
            const startDate = dayjs(att.startDate || att.date);
            const endDate = dayjs(att.endDate || att.date);

            // Check if the current date is within the attendance date range
            return currentDate.isBetween(startDate, endDate, 'day', '[]'); // '[]' includes start and end date
          }
          return false;
        });

        // Mark the attendance status based on the type
        userAttendance[formattedDate] = dayAttendance
          ? dayAttendance.status === 2
            ? dayAttendance.type === 1
              ? 'Casual Leave'
              : dayAttendance.type === 2
                ? 'Permission'
                : dayAttendance.type === 3
                  ? 'Sick Leave'
                  : dayAttendance.type === 4
                    ? 'Marriage Leave'
                    : dayAttendance.type === 5
                      ? 'Work From Home'
                      : dayAttendance.type === 6
                        ? 'Maternity Leave'
                        : dayAttendance.type === 7
                          ? 'Paternity Leave'
                          : 'Absent'
            : 'Present'
          : 'P';
      }
      return userAttendance;
    });

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Attendance');
    const workbookOut = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    saveAs(
      new Blob([workbookOut], { type: 'application/octet-stream' }),
      `Attendance_${dayjs(selectedMonth).format('MMMM_YYYY')}.xlsx`,
    );
  };

  const calculateSummaryColumns = (record) => {
    const daysInMonth = dayjs(selectedMonth).daysInMonth();
    const isCurrentMonth = dayjs(selectedMonth).isSame(dayjs(), 'month');
    const lastDayToCount = isCurrentMonth ? dayjs().date() : daysInMonth;
    let workingDays = 0;
    let daysAbsent = 0;
    let daysPresentTillNow = 0;
    let casualLeaveRemaining = 12;
    let sickLeaveRemaining = 6;
    let daysAbsentInMonth = 0;

    const holidayMap = holidaysList.reduce((map, holiday) => {
      const holidayDate = dayjs(holiday.date).format('YYYY-MM-DD');
      map[holidayDate] = true;
      return map;
    }, {});

    // Calculate total leave taken for this user
    const totalLeaveTaken = attendance.reduce(
      (leaveSummary, att) => {
        if (att?.userId?._id === record._id) {
          const currentStartDate = dayjs(att.startDate || att.date);
          const currentEndDate = dayjs(att.endDate || att.date);

          // Calculate leave duration (for newer records with start and end date)
          let leaveDuration = currentEndDate.diff(currentStartDate, 'day') + 1; // +1 to include endDate

          // Adjust based on leave type and accumulate in leaveSummary
          if (att.type === 1) {
            if (att.session === 1 || att.session === 2) {
              leaveDuration = 0.5; // Half day leave
            }
            leaveSummary.casualLeave += leaveDuration;
          } else if (att.type === 3) {
            // Check if the session is for a half-day (session 1 or session 2)
            if (att.session === 1 || att.session === 2) {
              leaveDuration = 0.5; // Half day leave
            }
            leaveSummary.sickLeave += leaveDuration;
          } else if (att.type === 4) {
            // For marriage leave, also check for half-day sessions
            if (att.session === 1 || att.session === 2) {
              leaveDuration = 0.5; // Half day leave
            }
            leaveSummary.marriageLeave += leaveDuration;
          }
        }
        return leaveSummary;
      },
      { casualLeave: 0, sickLeave: 0, marriageLeave: 0 },
    );

    // Deduct the total leaves from the remaining leave balances
    casualLeaveRemaining -= totalLeaveTaken.casualLeave;
    sickLeaveRemaining -= totalLeaveTaken.sickLeave;

    for (let day = 1; day <= daysInMonth; day++) {
      const currentDate = dayjs(selectedMonth).date(day);
      const dayOfWeek = currentDate.format('ddd');
      const formattedDate = currentDate.format('YYYY-MM-DD');

      if (
        dayOfWeek === 'Sat' ||
        dayOfWeek === 'Sun' ||
        holidayMap[formattedDate]
      ) {
        continue;
      }

      workingDays++;

      const dayAttendance = attendance.find((att) => {
        if (att?.userId?._id === record._id) {
          if (att.startDate && att.endDate) {
            // For newer records with startDate and endDate
            return (
              dayjs(att.startDate).isSameOrBefore(currentDate, 'day') &&
              dayjs(att.endDate).isSameOrAfter(currentDate, 'day')
            );
          } else if (att.date) {
            // For older records with a single date field
            return dayjs(att.date).isSame(currentDate, 'day');
          }
        }
        return false;
      });

      if (
        dayAttendance &&
        dayAttendance.status === 2 &&
        dayAttendance.type === 2
      ) {
        if (dayAttendance.session === 1 || dayAttendance.session === 2) {
          daysAbsent += 0.5;
        }
      }
      if (
        dayAttendance &&
        dayAttendance.status === 2 &&
        dayAttendance.type !== 5
      ) {
        if (dayAttendance.session === 1 || dayAttendance.session === 2) {
          daysAbsent += 0.5;
          if (day <= lastDayToCount) {
            daysAbsentInMonth += 0.5; // Half-day absence within the month
          }
        } else if (day <= lastDayToCount) {
          daysAbsentInMonth++; // Full-day absence within the month
        }
      }

      if (day <= lastDayToCount) {
        if (!dayAttendance || dayAttendance.status === 2) {
          daysPresentTillNow++;
        }
      }
    }

    const totalDaysAbsent =
      totalLeaveTaken.casualLeave +
      totalLeaveTaken.sickLeave +
      totalLeaveTaken.marriageLeave +
      daysAbsent;

    const daysPresent = daysPresentTillNow - totalDaysAbsent;

    const daysPrsentInMonth = workingDays - daysAbsentInMonth;

    return {
      workingDays,
      daysPresent,
      daysAbsent,
      totalDaysAbsent,
      daysPresentTillNow,
      casualLeaveRemaining,
      sickLeaveRemaining,
      daysAbsentInMonth,
      daysPrsentInMonth,
    };
  };

  const summaryColumns = [
    {
      title: t('No of Working Days'),
      key: 'workingDays',
      render: (_, record) => {
        const { workingDays } = calculateSummaryColumns(record);
        return (
          <div style={{ textAlign: 'center' }}>
            {workingDays !== null ? workingDays : '-'}
          </div>
        );
      },
      fixed: 'right',
    },
    {
      title: t('No of Days Present'),
      key: 'daysPresent',
      render: (_, record) => {
        const { daysPrsentInMonth } = calculateSummaryColumns(record);
        return (
          <div style={{ textAlign: 'center' }}>
            {daysPrsentInMonth !== null ? daysPrsentInMonth : '-'}
          </div>
        );
      },
      fixed: 'right',
    },
    {
      title: t('No of Days Absent'),
      key: 'daysAbsent',
      render: (_, record) => {
        const { daysAbsentInMonth } = calculateSummaryColumns(record);
        return (
          <div style={{ textAlign: 'center' }}>
            {daysAbsentInMonth !== null ? daysAbsentInMonth : '-'}
          </div>
        );
      },
      fixed: 'right',
    },
    {
      title: t(' Casual Leaves Remaining'),
      key: 'casualLeaveRemaining',
      render: (_, record) => {
        const { casualLeaveRemaining } = calculateSummaryColumns(record);
        return (
          <div style={{ textAlign: 'center' }}>{casualLeaveRemaining}</div>
        );
      },
      fixed: 'right',
    },
    {
      title: t(' Sick Leaves Remaining'),
      key: 'sickLeaveRemaining',
      render: (_, record) => {
        const { sickLeaveRemaining } = calculateSummaryColumns(record);
        return <div style={{ textAlign: 'center' }}>{sickLeaveRemaining}</div>;
      },
      fixed: 'right',
    },
  ];

  const baseColumns = [
    {
      title: t('Employees Name'),
      dataIndex: 'first_name',
      key: 'first_name',
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
      filteredValue: null,
      fixed: 'left',
    },
  ];

  const dynamicColumns = generateDaysColumns();

  const columns = [...baseColumns, ...dynamicColumns, ...summaryColumns];

  return (
    <div className="w-full px-4 p-3 flex-row items-start overflow-auto">
      <FloatButton.BackTop />

      <div className="flex flex-col md:flex-row md:justify-between items-end gap-2 py-4 mt-2">
        <div className="w-full flex flex-row gap-5 items-center justify-start">
          <div className="flex flex-row gap-3 items-center">
            <FaCheck style={{ color: 'blue', fontSize: 20 }} />
            <p style={{ color: 'blue', fontSize: '12px' }}>{t('permission')}</p>
          </div>
          <div className=" flex flex-row gap-3 items-center">
            <FaSun style={{ color: 'orange', fontSize: 20 }} />
            <p style={{ color: 'orange', fontSize: '12px' }}>
              {t('forenoon-absent')}
            </p>
          </div>
          <div className=" flex flex-row gap-3 items-center">
            <BsFillCloudSunFill
              style={{ color: 'magenta', height: '18px', width: '18px' }}
            />
            <p style={{ color: 'magenta', fontSize: '12px' }}>
              {t('afternoon-absent')}
            </p>
          </div>
          {/* <div className=" flex flex-row gap-3 items-center">
            <IoWarning style={{ color: 'red', fontSize: 20 }} />
            <p style={{ color: 'red', fontSize: '12px' }}>{t('absent')}</p>
          </div> */}
          <div className="flex flex-row gap-3 items-center">
            <ImCross style={{ color: 'red', fontSize: 18 }} />
            <p style={{ color: 'red', fontSize: '12px' }}>
              {t('fullday-absent')}
            </p>
          </div>
          <div className=" flex flex-row gap-3 items-center">
            <FaCheck style={{ color: 'green', fontSize: 20 }} />
            <p style={{ color: 'green', fontSize: '12px' }}>{t('present')}</p>
          </div>
          <div className="flex flex-row gap-3 items-center">
            <FaHome style={{ color: 'lime', fontSize: 20 }} />
            <p style={{ color: 'lime', fontSize: '12px' }}>
              {t('Work From Home')}
            </p>
          </div>
          <div className="flex flex-row gap-3 items-center">
            <FaHome style={{ color: 'orange', fontSize: 20 }} />
            <p style={{ color: 'orange', fontSize: '12px' }}>
              {t('Work From Home Forenoon')}
            </p>
          </div>
          <div className="flex flex-row gap-3 items-center">
            <FaHome style={{ color: 'blue', fontSize: 20 }} />
            <p style={{ color: 'blue', fontSize: '12px' }}>
              {t('Work From Home Afternoon')}
            </p>
          </div>
        </div>

        <div className="flex md:w-1/2 flex-row items-end gap-2 justify-end">
          <div className="w-1/2  flex flex-col gap-2">
            <Typography value={t('Select Month')} />
            <DatePicker
              onChange={handleDateRangeChange}
              selectedValue={selectedMonth}
              picker="month"
              format="MMM-YYYY"
              //disabledDate={disabledDate}
            />
          </div>
          {/* <div className="w-full flex flex-col gap-2">
            <Typography value={t('selectUser')} />
            <Select
              options={usersList}
              className={'w-full'}
              onChange={selectUsers}
              placeholder={t('selectaUser')}
            />
          </div> */}

          <div className=" flex flex-col  gap-2 justify-center item-center">
            <Button
              type="primary"
              className="items-center justify-center"
              icon={<AiOutlineExport />}
              onClick={handleExport}
            >
              {t('Export')}
            </Button>
          </div>
        </div>
      </div>

      {roles?.includes('admin') && (
        <div className="w-[100%]">
          <div className="overflow-x-auto ">
            <Table
              dataSource={usersList}
              columns={columns}
              className="w-full "
              onChange={handleStatusFilterChange}
              pagination={{
                current: pageCount,
                pageSize: 500,
              }}
              loading={loading}
            />
          </div>
        </div>
      )}
    </div>
  );
}
