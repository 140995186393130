import { Menu, Layout, Dropdown } from 'antd';
import {
  UserOutlined,
  ArrowRightOutlined,
  AppstoreAddOutlined,
  DashboardOutlined,
  CalendarOutlined,
  FileTextOutlined,
  FileSearchOutlined,
  BuildOutlined,
  GroupOutlined,
  ProjectOutlined,
  FileAddOutlined,
  MoneyCollectOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { signoutSuccess } from '../redux/user/userSlice';
import { useDispatch } from 'react-redux';
import { clearAuthToken } from '../utils/api';
import { useTranslation } from 'react-i18next';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import routesConfig from '../routes/routes.json';
import { IoMenu } from 'react-icons/io5';

export default function DashSidebar() {
  const signOut = useSignOut();
  const navigate = useNavigate();
  const authUser = useAuthUser();
  const roles = authUser?.rolesType;
  const userId = authUser?._id;
  const { Sider } = Layout;

  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const [tab, setTab] = useState('');
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const tabFromUrl = urlParams.get('tab');
    if (tabFromUrl) {
      setTab(tabFromUrl);
    } else {
      setTab(location.pathname);
    }
  }, [location.search, location.pathname]);

  const handleSignout = async () => {
    try {
      signOut();
      clearAuthToken();
      dispatch(signoutSuccess());
      navigate('/');
    } catch (error) {
      console.log(error.message);
    }
  };

  function getItem(key, icon, title, label, children, onClick) {
    return {
      key,
      icon,
      title,
      label:
        typeof label === 'string' ? (
          label
        ) : (
          <span onClick={onClick}>{label}</span>
        ),
      children,
      onClick,
    };
  }

  let menuItems = [];

  const adminMenuItems = [
    getItem(
      routesConfig.dashboard,
      <DashboardOutlined />,
      t('dashboard'),
      t('dashboard'),
      null,
      () => navigate(routesConfig.dashboard),
    ),
    getItem('att-key', <CalendarOutlined />, t('Attendance'), t('Attendance'), [
      getItem(
        routesConfig.attendance,
        <FileTextOutlined />,
        t('attendList'),
        <Link to={routesConfig.attendance}>{t('attendList')}</Link>,
      ),
      getItem(
        routesConfig.absentList,
        <FileSearchOutlined />,
        t('absentList'),
        <Link to={routesConfig.absentList}>{t('absentList')}</Link>,
      ),
      getItem(
        routesConfig.wfoList,
        <FileAddOutlined />,
        'WOH Absent List',
        <Link to={routesConfig.wfoList}>{'WFO List'}</Link>,
      ),
      getItem(
        routesConfig.holidayList,
        <CalendarOutlined />,
        t('holidayList'),
        <Link to={routesConfig.holidayList}>{t('holidayList')}</Link>,
      ),
      getItem(
        routesConfig.attendanceReport,
        <FileSearchOutlined />,
        t('Report'),
        <Link to={routesConfig.attendanceReport}>{t('Report')}</Link>,
      ),
      getItem(
        routesConfig.attendanceYaerlyReport,
        <FileTextOutlined />,
        t('ReportYearly'),
        <Link to={routesConfig.attendanceYaerlyReport}>
          {t('ReportYearly')}
        </Link>,
      ),
    ]),
    // asset
    getItem(
      routesConfig.asset,
      <AppstoreAddOutlined />,
      t('asset'),
      <Link to={routesConfig.asset}>{t('asset')}</Link>,
    ),
    getItem(
      routesConfig.projectsAllocate,
      <BuildOutlined />,
      t('assign-proj'),
      <Link to={routesConfig.projectsAllocate}>{t('assign-proj')}</Link>,
    ),
    getItem(
      routesConfig.config,
      <SettingOutlined />,
      t('config'),
      <Link to={routesConfig.config}>{t('config')}</Link>,
    ),
    getItem(
      routesConfig.department,
      <GroupOutlined />,
      t('department'),
      t('department'),
      null,
      () => navigate(routesConfig.department),
    ),
    getItem(
      routesConfig.users,
      <AppstoreAddOutlined />,
      t('employees'),
      <Link to={routesConfig.users}>{t('employees')}</Link>,
    ),
    getItem('project-Sidebar', <ProjectOutlined />, t('projects'), t('projects'), [
      getItem(
        routesConfig.projects,
        <ProjectOutlined />,
        t('projects'),
        <Link to={routesConfig.projects}>{t('projects')}</Link>,
      ),
      getItem(
        routesConfig.subProjects,
        <ProjectOutlined />,
        t('subProjects'),
        <Link to={routesConfig.subProjects}>{t('subProjects')}</Link>,
      ),
    ]),
    getItem(
      routesConfig.rolesv1,
      <UserOutlined />,
      t('roles'),
      t('roles'),
      null,
      () => navigate(routesConfig.rolesv1),
    ),
  ];

  if (roles?.includes('admin')) {
    menuItems = menuItems.concat(adminMenuItems);
  }
  menuItems.push(
    getItem(
      routesConfig.profile,
      <UserOutlined />,
      t('profile'),
      t('profile'),
      null,
      () => navigate(routesConfig.profile),
    ),
    getItem(
      routesConfig.modules,
      <AppstoreAddOutlined />,
      t('module/activity'),
      t('module/activity'),
      null,
      () => navigate(routesConfig.modules),
    ),
    getItem(
      routesConfig.expenses,
      <MoneyCollectOutlined />,
      t('expenses'),
      t('expenses'),
      null,
      () => navigate(routesConfig.expenses),
    ),
    // request
    getItem(
      routesConfig.request,
      <FileAddOutlined />,
      t('request'),
      <Link to={routesConfig.request}>{t('request')}</Link>,
    ),
    getItem(
      routesConfig.workLogReportv1,
      <FileSearchOutlined />,
      t('work-log'),
      t('work-log'),
      null,
      () => navigate(routesConfig.workLogReportv1),
    ),
    getItem(
      'administration',
      <ArrowRightOutlined />,
      t('administration'),
      t('administration'),
      [
        getItem(
          routesConfig.settings,
          <SettingOutlined />,
          t('settings'),
          <Link to={routesConfig.settings}>{t('settings')}</Link>,
        ),
        {
          key: 'signOut',
          icon: <ArrowRightOutlined />,
          label: t('signOut'),
          onClick: handleSignout,
        },
      ],
    ),
  );

  const menuItemStyle = (item) => ({
    color: item.key === tab ? 'green' : 'inherit',
  });

  return (
    <>
      {userId && window.innerWidth < 768 && (
        <Dropdown style={{ marginLeft: 10 }} menu={{ items: menuItems }}>
          <button
            style={{
              width: 'fit-content',
              position: 'fixed',
              top: '25px',
              zIndex: 999,
              left: '20px',
            }}
          >
            <IoMenu style={{ fontSize: '1.5rem' }} />
          </button>
        </Dropdown>
      )}
      {userId && window.innerWidth > 768 && (
        <Sider
          collapsible
          className="site-layout-background"
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          style={{
            overflow: 'auto',
            left: 0,
            height: '100vh',
            zIndex: 99,
          }}
        >
          <Menu
            mode="inline"
            selectedKeys={[tab]}
            items={menuItems.map((item) => ({
              ...item,
              label: <span style={menuItemStyle(item)}>{item.label}</span>,
            }))}
          />
        </Sider>
      )}
    </>
  );
}
