import {
  Message,
  TextInput,
  Input,
  Button,
  // Spinner,
  Typography,
  FloatButton,
} from '../../utils/antd-components/index.tsx';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  signInStart,
  signInPending,
  signInFailure,
  SignInRiderect,
} from '../../redux/user/userSlice.js';
import { useTranslation } from 'react-i18next';
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import { ApiUtils } from '../../utils/api.jsx';
import PATHS from '../../utils/constants/Paths.tsx';
import METHOD_TYPES from '../../utils/constants/MethodTypes.tsx';
import routesnkt from '../../routes/routes.json';
import { SparklesCore } from '../../utils/UiComponents/sparkles.tsx';
export default function SignIn() {
  const signIn = useSignIn();
  const { t } = useTranslation();
  const [formData, setFormData] = useState({});
  const {
    error: errorMessage,
    success: successMessage,
    redirect,
  } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value.trim().toLowerCase(),
    }));
    console.log('EMAIL>>>>>>>..>>>>===', value.trim().toLowerCase());
  };

  const handlePasswordChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value.trim(),
    }));
    console.log('passwordd>>>>>>>>>>...===', value.trim());
  };

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(signInPending(''));
    dispatch(signInFailure(''));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!formData.email || !formData.password) {
      return dispatch(signInFailure(t('please-fill-all-the-fields')));
    }
    try {
      // console.log('cameherelogin=======')
      dispatch(signInStart());
      const URL = PATHS.AUTH.LOGIN;
      const data = await ApiUtils(URL, formData, METHOD_TYPES.POST);
      if (data.status !== 200 && data.status !== 401) {
        dispatch(signInFailure(data.message));
      } else if (data.status === 401) {
        dispatch(signInPending(data));
        navigate(routesnkt.userVerify);
      }

      if (data.status === 200) {
        // dispatch(signInSuccess(data));
        // // store token
        // localStorage.setItem('token', data.token);

        if (
          signIn({
            auth: {
              token: data.token,
              type: 'Bearer',
            },
            //refresh: 'ey....mA',
            userState: {
              name: data.user.first_name,
              _id: data.user._id,
              userDetails: data.user,
              email: data.user.email,
              roles: data.user.roleId._id,
              rolesType: data.user.roleId.type,
            },
          })
        ) {
          if (redirect) {
            let redirects = redirect;
            dispatch(SignInRiderect(''));
            navigate(redirects);
          } else {
            const roletype = data.user?.roleId?.type;
            if (roletype.includes('admin')) {
              navigate(routesnkt.workLogReportv1);
            } else {
              navigate(routesnkt.workLogReportv1);
            }
          }
        } else {
          dispatch(signInFailure(t('error-pls-try-again')));
        }
      }
      setLoading(false);
    } catch (error) {
      console.log('errorhere=====', error);
      dispatch(signInFailure(error.message));
    }
  };

  return (
    <div className="min-h-screen">
      <div className="flex h-screen">
        <div className="hidden lg:flex items-center justify-center flex-1 bg-white text-black">
          <div className="w-full text-center">
            <div className="h-[45rem] w-full bg-black flex flex-col items-center justify-center overflow-hidden rounded-md">
              <h1 className="md:text-7xl text-3xl lg:text-5xl font-bold text-center text-white relative z-20">
                Netkathir Technologies
              </h1>
              <div className="w-[40rem] h-40 relative">
                {/* Gradients */}
                <div className="absolute inset-x-20 top-0 bg-gradient-to-r from-transparent via-indigo-500 to-transparent h-[2px] w-3/4 blur-sm" />
                <div className="absolute inset-x-20 top-0 bg-gradient-to-r from-transparent via-indigo-500 to-transparent h-px w-3/4" />
                <div className="absolute inset-x-60 top-0 bg-gradient-to-r from-transparent via-sky-500 to-transparent h-[5px] w-1/4 blur-sm" />
                <div className="absolute inset-x-60 top-0 bg-gradient-to-r from-transparent via-sky-500 to-transparent h-px w-1/4" />

                {/* Core component */}
                <SparklesCore
                  background="transparent"
                  minSize={0.4}
                  maxSize={1}
                  particleDensity={1200}
                  className="w-full h-full"
                  particleColor="#FFFFFF"
                />

                {/* Radial Gradient to prevent sharp edges */}
                <div className="absolute inset-0 w-full h-full bg-black [mask-image:radial-gradient(350px_200px_at_top,transparent_20%,white)]"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full bg-slate-50 lg:w-1/2 flex items-center justify-center">
          <FloatButton.BackTop />
          <div className="flex bg-white p-3 sm:w-auto sm:mx-5 w-full md:w-1/2 lg:mx-auto flex-col md:items-center rounded overflow-hidden shadow-lg gap-5">
            <div className="flex-1 m-10">
              <div className="pb-0 mb-0 bg-transparent border-b-0 rounded-t-2xl">
                <h3 className="relative z-10 font-bold text-transparent bg-gradient-to-tl  from-teal-200 to-teal-500 bg-clip-text">
                  Welcome back
                </h3>
                <p className="mb-0 text-black">
                  Enter your email and password to sign in
                </p>
              </div>
              <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
                <div className="w-full flex flex-col gap-2 sm:flex-col justify-between mt-10">
                  <Typography value={t('email')} color="black" />
                  <TextInput
                    type="text"
                    placeholder="name@company.com"
                    id="email"
                    onChange={handleEmailChange}
                  />
                </div>
                <div className="w-full flex flex-col gap-2 sm:flex-col justify-between">
                  <Typography value={t('password')} color="black" />
                  <Input
                    placeholder="**********"
                    id="password"
                    onChange={handlePasswordChange}
                  />
                </div>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  disabled={loading}
                  className="w-full font-bold "
                >
                  {t('signIn')}
                </Button>
              </form>
              {errorMessage && (
                <Message
                  className="mt-5"
                  type="error"
                  successMessage={errorMessage}
                />
              )}
              {successMessage && (
                <Message successMessage={successMessage} type="success" />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
