import { useLocation, useParams, useNavigate } from 'react-router-dom';

const withRouter = (Component) => {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const navigate = useNavigate();

    const params = useParams();
    return (
      <Component
        {...props}
        location={location}
        navigate={navigate}
        router={{ params }}
      />
    );
  }
  return ComponentWithRouterProp;
};

export default withRouter;
