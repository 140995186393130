import {
  Typography,
  Select,
  TextInput,
  Message,
  DatePicker,
  Button,
  FloatButton,
  Modal,
} from '../../utils/antd-components/index.tsx';
import { Form } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useEffect, useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiUtils } from '../../utils/api.jsx';
import { useTranslation } from 'react-i18next';
import PATHS from '../../utils/constants/Paths.tsx';
import METHOD_TYPES from '../../utils/constants/MethodTypes.tsx';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import TitleAndBackComponent from '../../utils/UiComponents/titleAndBackComponent.tsx';
import dayjs from 'dayjs';
import { IoAddCircleSharp } from 'react-icons/io5';

import routesnkt from '../../routes/routes.json';

export default function UpdateModule() {
  const { t } = useTranslation();
  const { postId } = useParams();
  const [form] = Form.useForm();
  const authUser = useAuthUser();
  const userId = authUser._id;
  const roles = authUser.rolesType;
  const navigate = useNavigate();

  const [publishError, setPublishError] = useState(null);
  const [projectList, setProjectList] = useState(null);
  const [moduleList, setModulesList] = useState(null);
  const [userList, setUsersList] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    content: '',
    status: 1,
    date: dayjs().format('YYYY-MM-DD'),
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newModuleName, setNewModuleName] = useState('');

  const fetUsersList = async () => {
    const URL = `${PATHS.USER.GET}?limit=100`;
    const data = await ApiUtils(URL);
    if (data.status !== 200) {
      console.log(data.message);
      setPublishError(data.message);
      return;
    } else {
      setPublishError(null);
      setUsersList(data.users);
    }
  };

  const fetchUpdateWorkLog = async () => {
    try {
      let URL;
      if (roles.includes('admin')) {
        URL = `${PATHS.WORKLOG.GET}?_id=${postId}&limit=100`;
      } else {
        URL = `${PATHS.WORKLOG.GET}?userId=${userId}&_id=${postId}&limit=100`;
      }
      const data = await ApiUtils(URL);
      if (data.status !== 200) {
        console.log(data.message);
        setPublishError(data.message);
        return;
      }
      setPublishError(null);
      const fetchedData = data.response[0];
      setFormData(fetchedData);
      // Set form fields value
      form.setFieldsValue({
        ...fetchedData,
        date: dayjs(fetchedData.date),
        projectId: fetchedData.projectId._id,
        moduleId: fetchedData.moduleId._id,
        userId: fetchedData.userId._id,
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchUpdateWorkLog();
    if (roles.includes('admin')) {
      fetUsersList();
    }
  }, []);

  const handleAddModuleClick = () => {
    setIsModalVisible(true);
  };

  const handleModalOk = async () => {
    if (!newModuleName || newModuleName.length < 3) {
      setError('Module name must be at least 3 characters long');
      return;
    }
    try {
      const payload = {
        name: newModuleName,
        projectId:
          typeof formData.projectId === 'object'
            ? formData.projectId._id
            : formData.projectId,
        userId:
          typeof formData.userId === 'object'
            ? formData.userId._id
            : formData.userId,
      };
      const URL = PATHS.MODULES.ADD;
      const data = await ApiUtils(URL, payload, 'POST');
      if (data.status !== 200) {
        setPublishError(data.message);
      } else {
        setPublishError(null);
        setModulesList((prevModulesList) => [
          ...prevModulesList,
          data.response,
        ]);
        console.log('data.response', data.response);
        setFormData({ ...formData, moduleId: data.response._id });
        form.setFieldsValue({ moduleId: data.response._id });
      }
    } catch (error) {
      setPublishError('Error adding module');
    }
    setIsModalVisible(false);
    setNewModuleName('');
    setError(null);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setNewModuleName('');
    setError(null);
  };

  useEffect(() => {
    try {
      const fetchProjectList = async () => {
        let URL;
        if (roles.includes('admin') && formData.userId) {
          URL = `${PATHS.PROJECTASSIGN.GET}?userId=${form.getFieldValue(
            'userId',
          )}&limit=100`;
        } else {
          URL = `${PATHS.MODULES.PROJECT}?userId=${userId}&limit=100`;
        }
        const data = await ApiUtils(URL);
        if (data.status !== 200) {
          console.log(data.message);
          setPublishError(data.message);
          return;
        } else {
          setPublishError(null);
          setProjectList(data.response);
        }
      };
      if (
        (!roles.includes('admin') && userId) ||
        (roles.includes('admin') && formData.userId)
      ) {
        fetchProjectList();
      }
    } catch (error) {
      console.log('err in fetching user list', error.message);
    }
  }, [userId, formData.userId]);

  useEffect(() => {
    try {
      const fetchModuleList = async () => {
        let URL;
        if (roles.includes('admin') && form.getFieldValue('userId')) {
          URL = `${PATHS.MODULES.GET}?userId=${form.getFieldValue(
            'userId',
          )}&projectId=${form.getFieldValue('projectId')}&limit=100`;
        } else {
          URL = `${
            PATHS.MODULES.GET
          }?userId=${userId}&projectId=${form.getFieldValue(
            'projectId',
          )}&limit=100`;
        }
        const data = await ApiUtils(URL);
        if (data.status !== 200) {
          console.log(data.message);
          setPublishError(data.message);
          return;
        } else {
          setPublishError(null);
          setModulesList(data.response);
        }
      };
      if (
        !roles.includes('admin') &&
        userId &&
        form.getFieldValue('projectId')
      ) {
        fetchModuleList();
      } else if (
        roles.includes('admin') &&
        form.getFieldValue('userId') &&
        form.getFieldValue('projectId')
      ) {
        fetchModuleList();
      }
    } catch (error) {
      console.log('err in fetching user list', error.message);
    }
  }, [userId, form.getFieldValue('userId'), form.getFieldValue('projectId')]);

  const handleSubmit = async (e) => {
    setLoading(true);
    try {
      const formDataToSend = {
        ...e,
        projectId:
          typeof formData.projectId === 'object'
            ? formData.projectId._id
            : formData.projectId,
        userId:
          typeof formData.userId === 'object'
            ? formData.userId._id
            : formData.userId,
        moduleId:
          typeof formData.moduleId === 'object'
            ? formData.moduleId._id
            : formData.moduleId,
      };
      const URL = `${PATHS.WORKLOG.UPDATE}/${postId}`;
      const data = await ApiUtils(URL, formDataToSend, METHOD_TYPES.PUT);
      setLoading(true);
      if (data.status !== 200) {
        setPublishError(data.message);
        return;
      } else {
        setPublishError(null);
        navigate(routesnkt.workLogReportv1);
      }
      setLoading(false);
    } catch (error) {
      setPublishError(t('something-went-wrong'));
    } finally {
      setLoading(false);
    }
  };

  const handleProjectChange = (e) => {
    setFormData({ ...formData, projectId: e, moduleId: '' });
    form.setFieldValue('moduleId', null);
  };

  const handleModuleChange = (moduleId) => {
    setFormData({ ...formData, moduleId }); // Update the moduleId in formData
    form.setFieldsValue({ moduleId }); // Update the form field value
  };
  const handleUserChange = (e) => {
    setFormData({ ...formData, userId: e, projectId: '', moduleId: '' });
    form.setFieldValue('projectId', null);
    form.setFieldValue('moduleId', null);
  };

  const handleTimeNameChange = (e) => {
    const value = e.target.value;
    const timeRegex = /^(0[0-9]|1[0-9]|2[0-3]).[0-5][0-9]$/; // Regular expression for HH:mm format

    if (timeRegex.test(value)) {
      setFormData({ ...formData, time: e.target.value });
      setError(null);
    } else {
      setFormData({ ...formData, time: e.target.value });
      setError(t('invalid-time-format'));
      console.log('Invalid time format');
    }
  };

  return (
    <div className="p-3 max-w-3xl mx-auto min-h-screen">
      <FloatButton.BackTop />
      <TitleAndBackComponent
        title={t('updateWorklog')}
        goBackText={t('back')}
      />
      <Form
        className="flex flex-col gap-4"
        form={form}
        initialValues={{ date: dayjs() }}
        onFinish={handleSubmit}
      >
        <div className="flex flex-col gap-4 justify-between mt-6">
          {roles.includes('admin') && (
            <div className="w-full flex flex-col gap-2 mt-4">
              <Typography value={t('selectUser')} />

              <Form.Item
                name="userId"
                rules={[{ required: roles.includes('admin') ? true : false }]}
              >
                <Select
                  className={'h-10'}
                  required={true}
                  onChange={(e) => handleUserChange(e)}
                  options={userList}
                  placeholder={t('selectaUser')}
                  selectedValue={form.getFieldValue('userId')}
                  id={'_id'}
                />
              </Form.Item>
            </div>
          )}
          <div className="w-full flex flex-col gap-2 mt-3">
            <Typography value={t('selectProject')} />
            <Form.Item name="projectId" rules={[{ required: true }]}>
              <Select
                className={'h-10'}
                required={true}
                disabled={roles.includes('admin') && !formData.userId}
                onChange={(e) => handleProjectChange(e)}
                options={projectList}
                onClear={(e) => setFormData({ ...formData, projectId: e })}
                placeholder={t('selectaProject')}
                label={'projectId.name'}
                id={'projectId._id'}
                selectedValue={form.getFieldValue('projectId')}
              />
            </Form.Item>
          </div>
        </div>
        <div className="flex flex-col md:flex-col md:justify-between items-end gap-2 py-4 mt-2">
          <div className="w-full flex flex-col gap-2">
            <div style={{ display: 'flex', gap: 10, color: 'green' }}>
              <Typography value={t('modulesName')} />{' '}
              {formData.projectId && (
                <IoAddCircleSharp
                  style={{ fontSize: 25 }}
                  onClick={handleAddModuleClick}
                />
              )}
            </div>
            <Form.Item name="moduleId" rules={[{ required: true }]}>
              <div style={{ display: 'flex', gap: 10 }}>
                <Select
                  required={true}
                  disabled={!formData.projectId}
                  onChange={(moduleId) => handleModuleChange(moduleId)}
                  options={moduleList}
                  selectedValue={form.getFieldValue('moduleId')}
                  placeholder={t('selectaModulesName')}
                  label={'name'}
                  id={'_id'}
                  className={'w-full h-10'}
                />
              </div>
            </Form.Item>
          </div>
          <Modal
            title={t('Add New Module')}
            visible={isModalVisible}
            onOk={handleModalOk}
            onCancel={handleModalCancel}
          >
            <TextInput
              placeholder={t('Module Name')}
              value={newModuleName}
              onChange={(e) => setNewModuleName(e.target.value)}
            />
            {error && <p className="text-red-500">{error}</p>}
          </Modal>
        </div>
        <div className="flex flex-row gap-4 justify-between mt-3">
          <div className="w-full flex flex-col gap-2">
            <Typography value={t('select-date')} />
            <Form.Item name="date" rules={[{ required: true }]}>
              <DatePicker
                placeholder="Select Date"
                format="DD-MM-YYYY"
                required
                id="date"
                className="h-10 w-full"
                onChange={(value) => setFormData({ ...formData, date: value })}
                selectedValue={dayjs(formData.date)}
              />
            </Form.Item>
          </div>

          <div className="w-full flex flex-col gap-2">
            <Typography value={t('selectduration')} />
            <Form.Item name="time" rules={[{ required: true }]}>
              <TextInput
                type="text"
                placeholder={t('hhmm')}
                required
                id="time"
                maxLength={5}
                className="flex-1"
                onChange={handleTimeNameChange}
              />
            </Form.Item>

            {error && <p className="text-red-500">{error}</p>}
          </div>
        </div>
        <div className="w-full flex flex-col gap-2 mt-3">
          <Typography value={t('work-log-Title')} />
          <Form.Item name="name" rules={[{ required: true }]}>
            <TextInput
              type="text"
              placeholder={t('work-log-Title')}
              required
              id="name"
              className="flex-1"
              maxLength={150}
            />
          </Form.Item>
        </div>
        <div className="w-full flex flex-col gap-2 mt-3">
          <Typography value={t('workLogDesc')} />
          <Form.Item name="content" rules={[{ required: true }]}>
            <ReactQuill
              theme="snow"
              placeholder={t('writeSomething')}
              className="h-40 mb-12"
            />
          </Form.Item>
        </div>
        <div className=" flex justify-end mt-4">
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={loading}
          >
            {t('updateWorklog')}
          </Button>
        </div>
      </Form>
      {publishError && <Message successMessage={publishError} type="error" />}
    </div>
  );
}
