import {
  Message,
  // Select,
  TextInput,
  Button,
  RadioGroup,
  Typography,
  FloatButton,
  DatePicker,
} from '../../utils/antd-components/index.tsx';
import { Form } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useEffect, useState } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { ApiUtils } from '../../utils/api.jsx';
import { useTranslation } from 'react-i18next';
import PATHS from '../../utils/constants/Paths.tsx';
import METHOD_TYPES from '../../utils/constants/MethodTypes.tsx';
import TitleAndBackComponent from '../../utils/UiComponents/titleAndBackComponent.tsx';
import routesnkt from '../../routes/routes.json';
import dayjs from 'dayjs';
import { Select } from 'antd';

export default function UpdateExpenses() {
  const location = useLocation();
  const record = location.state;

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [publishError, setPublishError] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    content: '',
    status: 1,
    date: dayjs().format('YYYY-MM-DD'),
  });

  const [successMessage, setsuccessMessage] = useState(null);

  const postId = useParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log('api call', record.record);

    const {
      expense_title,
      expense_type,
      expense_amount,
      date,
      description,
      ...restValues
    } = record.record;
    setFormData({
      expense_title: expense_title,
      expense_type: expense_type,
      expense_amount: expense_amount,
      date: date,
      description: description,
      restValues,
    });
    form.setFieldsValue({
      ...restValues,
      expense_title: expense_title,
      expense_type: expense_type,
      expense_amount: expense_amount,
      date: date,
      description: description,
    });
    console.log('get data ', formData);
  }, []);

  const handleSubmit = async (e) => {
    setLoading(true);

    try {
      const payload = { ...e };
      console.log('post id*********************', postId.postId);
      const URL = `${PATHS.EXPENSES.UPDATE}/${postId.postId}`;
      const data = await ApiUtils(URL, payload, METHOD_TYPES.PUT);
      setLoading(true);
      console.log('URLLLLL', URL);
      console.log('dataaaaaa', data);
      console.log('dataaaaaastatus', data.status);
      if (data.status === 200) {
        setsuccessMessage(
          data.response.message || 'Expense updated successfully',
        );
        setPublishError(null);
        navigate(routesnkt.expenses);
      } else {
        setPublishError(data.message || 'Failed to update Expense');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error updating Expense:', error);
      setPublishError('Something went wrong');
    }
  };
  // useEffect(() => {
  //   if (formData.imageUrl) {
  //     form.setFieldsValue({ imageUrl: formData.imageUrl });
  //   }
  // }, [formData.imageUrl, form]);
  const handleRadioChange = (e) => {
    const statusValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      status: statusValue,
    }));
  };

  return (
    <div className="p-3 max-w-3xl mx-auto min-h-screen">
      <FloatButton.BackTop />
      <TitleAndBackComponent
        title={t('UpdateExpenses')}
        goBackText={t('back')}
      />
      <Form
        form={form}
        initialValues={{ date: dayjs() }}
        onFinish={handleSubmit}
      >
        <div className="flex flex-row gap-4 justify-between">
          <div className="w-full flex flex-col gap-2">
            <Typography value={t('expensestype')} />
            <Form.Item
              name="expense_type"
              rules={[
                { required: true, message: t('expenses type is required') },
              ]}
            >
              {/* <TextInput
                    
                            placeholder={t('expenseType')}
                          /> */}

              <Select
                className="h-10"
                required={false}
                onChange={(value) => {
                  setFormData({ ...formData, expense_type: value });
                }}
                value={formData.expense_type} // Bind selected value
                placeholder={t('select')}
              >
                {/* Using Select.Option to wrap your options */}
                <Select.Option value="Travel">Travel</Select.Option>
                <Select.Option value="Meals">Meals</Select.Option>
                <Select.Option value="Software">Software</Select.Option>
                <Select.Option value="Training">Training</Select.Option>
                <Select.Option value="Others">Others</Select.Option>
              </Select>
            </Form.Item>
          </div>
        </div>

        <div className="w-full flex flex-col gap-2">
          <Typography value={t('expenseTitle')} />
          <Form.Item
            name="expense_title"
            rules={[
              { required: true, message: t('expenses title is required') },
            ]}
          >
            <TextInput
              // value={formData.dept_name}
              placeholder={t('expenseTitle')}
            />
          </Form.Item>
        </div>
        <div className="flex flex-col gap-4 sm:flex-col justify-between">
          <Typography value={t('desc')} />
          <Form.Item name="description" rules={[{ required: false }]}>
            <ReactQuill
              theme="snow"
              placeholder={t('writeSomething')}
              className="h-40 mb-12"
            />
          </Form.Item>
        </div>
        <div className="w-full flex flex-col gap-2">
          <Typography value={t('amount')} />
          <Form.Item
            name="expense_amount"
            rules={[
              { required: true, message: t('expenses amount is required') },
            ]}
          >
            <TextInput
              // value={formData.dept_name}
              placeholder={t('expensesamount')}
            />
          </Form.Item>
        </div>
        <div className="w-full flex flex-col gap-2">
          <Typography value={t('select-date')} />
          <Form.Item name="date" rules={[{ required: true }]}>
            <DatePicker
              placeholder="Select Date"
              format="DD-MM-YYYY"
              required
              id="date"
              className="h-10 w-full"
              onChange={(value) => setFormData({ ...formData, date: value })}
              selectedValue={dayjs(formData.date)}
            />
          </Form.Item>
        </div>
        <div className="flex flex-row gap-2 sm:flex-col justify-between ">
          <Typography value={t('Status')} />
          <Form.Item name="status" rules={[{ required: true }]}>
            <RadioGroup
              onChange={handleRadioChange}
              selectedValue={formData.status}
              t={t}
            />
          </Form.Item>
        </div>
        <div className="flex justify-end">
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={loading}
          >
            {t('UpdateExpenses')}
          </Button>
        </div>
      </Form>
      {publishError && <Message successMessage={publishError} type="error" />}
      {successMessage && (
        <Message successMessage={successMessage} type="success" />
      )}
    </div>
  );
}
