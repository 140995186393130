import { useEffect, useState } from 'react';
import {
  TextInput,
  RangePicker,
  Select,
  Button,
  Message,
  Typography,
  FloatButton,
} from '../../utils/antd-components/index.tsx';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import PATHS from '../../utils/constants/Paths.tsx';
import METHOD_TYPES from '../../utils/constants/MethodTypes.tsx';
import { ApiUtils } from '../../utils/api.jsx';
//import ReactQuill from 'react-quill';
import { SESSION, SESSION1 } from '../../utils/constants/index.js';
import dayjs from 'dayjs';
import routesnkt from '../../routes/routes.json';
import { useNavigate } from 'react-router-dom';
import TitleAndBackComponent from '../../utils/UiComponents/titleAndBackComponent.tsx';
// import session from 'redux-persist/lib/storage/session';
// import { frameData } from 'framer-motion';

const WFOCreateAttendance = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const authUser = useAuthUser();
  const navigate = useNavigate();
  const roles = authUser.rolesType;
  const [successMessage, setsuccessMessage] = useState(null);
  const [userList, setUsersList] = useState(null);
  const [publishError, setPublishError] = useState(null);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    const fetchUsersList = async () => {
      try {
        const URL = PATHS.USER.GET + '?status=1&limit=1000';
        const data = await ApiUtils(URL);
        if (data.status !== 200) {
          console.log(data.message);
          setPublishError(data.message);
          return;
        } else {
          setPublishError(null);
          setUsersList(data.users);
        }
      } catch (error) {
        console.log(error.message);
      }
    };

    if (roles.includes('admin')) {
      fetchUsersList();
    }
  }, [roles]);

  const handleSubmit = async (e) => {
    setLoading(true);
    try {
      const payload = {
        userId: formData.userId, // Ensure userId is included as an object
        session: parseInt(formData.session),
        type: 5,
        startDate: formData.startDate,
        endDate: formData.endDate,
        reason: formData.reason,
        time: e.time,
      };

      const URL = PATHS.ATTENDANCE.ADD;
      const data = await ApiUtils(URL, payload, METHOD_TYPES.POST);

      if (data.status !== 200) {
        setPublishError(data.message);
        setLoading(false);
        return;
      } else {
        setsuccessMessage(data.message);
        setPublishError(null);
        navigate(routesnkt.wfoList);
      }
    } catch (error) {
      setPublishError(t('something-went-wrong'));
    } finally {
      setLoading(false);
    }
  };

  const handleUserChange = (e) => {
    setFormData({ ...formData, userId: e });
  };
  const handleLeavetypeChange = (e) => {
    setFormData({ ...formData, type: e });
    const sess = formData.session == 3 ? '' : formData.session;
    const timeupdate =
      formData.session == 3
        ? '08:00'
        : formData.session == undefined
          ? ''
          : '04:00';
    console.log('update ++', timeupdate);
    if (e == 2) {
      setFormData({ ...formData, session: sess, type: e });
      form.setFieldsValue({ time: '' });
    } else {
      form.setFieldsValue({ time: timeupdate });
    }
  };
  const handleSessionChange = (e) => {
    setFormData({ ...formData, session: e });
    if (e === '2' || e === '1') {
      form.setFieldsValue({ time: '04:00' });
      console.log('q++', formData);
    } else if (e === '3') {
      form.setFieldsValue({ time: '08:00' });
    } else {
      form.setFieldsValue({ time: '' });
    }
  };
  const handleTimeChange = (e) => {
    setFormData({ ...formData, time: e.target.value });
  };

  // const handleReasonChange = (e) => {
  //   setFormData({ ...formData, reason: e });
  // };

  // Disable dates after today
  const disableFutureDates = (current) => {
    console.log('test', current);
    return current && current > dayjs().add(1, 'month').endOf('day');
  };

  const handleDateRangeChange = (dates) => {
    if (dates?.length === 2) {
      const [start, end] = dates;

      // Format the dates to UTC
      // const formattedStartDate = start ? dayjs(start).utc().format() : null;
      const formattedStartDate = start
        ? dayjs(start).format('YYYY-MM-DDTHH:mm:ss[Z]')
        : null;

      const formattedEndDate = end
        ? dayjs(end).format('YYYY-MM-DDTHH:mm:ss[Z]')
        : null;

      // Update dateRange and sendData with the formatted UTC dates

      setFormData({
        ...formData,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      });
    }
  };

  return (
    <div className="p-3 max-w-3xl mx-auto min-h-screen">
      <FloatButton.BackTop />
      <TitleAndBackComponent
        title={t('Mark Work From Home')}
        goBackText={t('back')}
      />
      <Form
        className="flex flex-col gap-4"
        form={form}
        initialValues={{ date: dayjs() }}
        onFinish={handleSubmit}
      >
        <div className="flex flex-row gap-4 justify-between mt-6">
          <div className="w-full flex flex-col gap-2">
            <Typography value={t('selectaUser')} />
            <Form.Item name="userId" rules={[{ required: true }]}>
              <Select
                className={'h-10'}
                onChange={handleUserChange}
                options={userList}
                placeholder={t('selectaUser')}
                id={'_id'}
              />
            </Form.Item>
          </div>
          <div className="w-full flex flex-col gap-2">
            <Typography value={t('Select Leave Type')} />
            <Form.Item name="type">
              <Select
                className={'h-10'}
                selectedValue="Work From Home "
                disabled={!formData.userId}
                onChange={handleLeavetypeChange}
                placeholder={t('Select Type')}
                label="name"
                id="5"
              />
            </Form.Item>
          </div>
        </div>
        <div className="w-full flex flex-col gap-2">
          <Typography value={t('Select Session')} />
          <Form.Item name="session" rules={[{ required: true }]}>
            <Select
              className={'h-10'}
              options={formData.type === '2' ? SESSION1 : SESSION}
              onChange={handleSessionChange}
              selectedValue={formData.session}
              placeholder={t('Select Session')}
              label="name"
              id="value"
            />
          </Form.Item>
        </div>

        <div className="flex flex-row gap-4 justify-between mt-3">
          <div className="w-full flex flex-col gap-2">
            <Typography value={t('select-date')} />
            <Form.Item name="date" rules={[{ required: true }]}>
              <RangePicker
                placeholder="Select Date"
                format="DD-MM-YYYY"
                required
                id="date"
                onChange={handleDateRangeChange}
                className="h-10 w-full"
                defaultValue={dayjs()}
                disabledDate={disableFutureDates}
              />
            </Form.Item>
          </div>
          <div className="w-full flex flex-col gap-2">
            {console.log('v++ hiiii', formData)}
            <Typography value={t('selectduration')} />
            <Form.Item name="time" rules={[{ required: true }]}>
              <TextInput
                type="text"
                placeholder={t('hhmm')}
                required
                id="time"
                maxLength={5}
                className="flex-1"
                onChange={handleTimeChange}
              />
            </Form.Item>
          </div>
        </div>

        {/* <div className="w-full flex flex-col gap-2 mt-3">
          <Typography value={t('Enter Reason')} />
          <Form.Item name="reason" rules={[{ required: true }]}>
            <ReactQuill
              theme="snow"
              placeholder={t('Enter Reason')}
              className="h-40 mb-12"
              onChange={handleReasonChange}
            />
          </Form.Item>
        </div> */}

        <div className=" flex justify-end mt-4">
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={loading}
          >
            {t('Mark Absent')}
          </Button>
        </div>
      </Form>
      {publishError && <Message successMessage={publishError} type="error" />}
      {successMessage && (
        <Message successMessage={successMessage} type="success" />
      )}
    </div>
  );
};

export default WFOCreateAttendance;
