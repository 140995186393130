import { FC } from 'react';
import { Select } from 'antd';

const { Option } = Select;

type OptionType = {
  _id: string;
  [key: string]: any; // Allows indexing into any property
};

interface CustomDropdownProps {
  onChange?: (value: any) => void;
  onClear?: () => void;
  onSearch?: (value: string) => void;
  options: OptionType[];
  placeholder?: string;
  label: string;
  id: string;
  selectedValue?: string;
  className?: string;
  disabled?: boolean;
  allowClear?: boolean;
  mode?: 'multiple' | 'tags';
  showSearch?: boolean;
}

const getNestedPropertyValue = (obj: any, path: string): any => {
  return path.split('.').reduce((acc, key) => (acc ? acc[key] : null), obj);
};

const filterOption = (input: string, option: any): boolean => {
  // Logs commented out, can be added back if needed for debugging
  // console.log('options===', option);
  // console.log('options===', input);
  return (option?.children || '').toLowerCase().includes(input.toLowerCase());
};

export const CustomDropdown: FC<CustomDropdownProps> = ({
  onChange,
  onClear,
  onSearch,
  options,
  placeholder,
  label = 'first_name',
  id = '_id',
  selectedValue,
  className,
  disabled,
  allowClear = true,
  mode,
  showSearch = true,
}) => {
  return (
    <Select
      className={className}
      onChange={onChange}
      onClear={onClear}
      disabled={disabled}
      value={selectedValue}
      optionFilterProp="children"
      mode={mode}
      showSearch={showSearch}
      placeholder={placeholder}
      onSearch={onSearch}
      filterOption={filterOption}
      allowClear={allowClear}
    >
      {options &&
        options.map((item) => (
          <Option
            key={item._id}
            value={
              id.includes('.') ? getNestedPropertyValue(item, id) : item[id]
            }
          >
            {label.includes('.')
              ? getNestedPropertyValue(item, label)
              : item[label]}
          </Option>
        ))}
    </Select>
  );
};
