import { Route } from 'react-router-dom';
import { Routes } from 'react-router-dom';
import LottieControl from '../utils/UiComponents/lottie.tsx';
import { Suspense } from 'react';
import routesnkt from './routes.json';
import Home from '../pages/Home/Home.jsx';
import About from '../pages/Others/About.jsx';
import SignIn from '../pages/Authentication/SignIn.jsx';
import Dashboard from '../pages/Dashboard/Dashboard.jsx';
import SignUp from '../pages/Authentication/SignUp.jsx';
import UserVerify from '../pages/Authentication/UserVerify.jsx';
import CreateUser from '../pages/Create/CreateUser.jsx';
import CreateSubProjects from '../pages/Create/CreateSubProjects.jsx';
import CreateProjects from '../pages/Create/CreateProjects.jsx';
import CreatRoles from '../pages/Create/CreatRole.jsx';
import CreateHolidays from '../pages/Create/CreateHolidays.jsx';
import CreateProjectAssign from '../pages/Create/CreateProjectAssign.jsx';
import CreateModules from '../pages/Create/CreateModules.jsx';
import CreateWorkLog from '../pages/Create/CreateWorkLog.jsx';
import UpdateModules from '../pages/Update/UpdateModules.jsx';
import UpdateWorkLog from '../pages/Update/UpdateWorkLog.jsx';
import UpdateRole from '../pages/Update/UpdateRole.jsx';
import UpdateSubProjects from '../pages/Update/updateSubProjects.jsx';
import UpdateProjects from '../pages/Update/UpdateProjects.jsx';
import UpdateUser from '../pages/Update/UpdateUser.jsx';
import UpdateUserTimings from '../pages/Update/UpdateUserTimings.jsx';
import UpdateProjectAssign from '../pages/Update/UpdateProjectAssign.jsx';
import CreateConfig from '../pages/Create/CreateConfig.jsx';
import UpdateConfig from '../pages/Update/UpdateConfig.jsx';
import OnlyAdminPrivateRoute from '../components/OnlyAdminPrivateRoute.jsx';
import OnlyUserPrivateRoute from '../components/OnlyUserPrivateRoute.jsx';
import DashRolev1 from '../pages/SidebarPages/roles/Roles.jsx';
import DashAttendance from '../pages/SidebarPages/attendance/DashAttendance.jsx';
import DashAttendanceReport from '../pages/SidebarPages/attendance/DashAttendanceReport.jsx';
import DashAttendanceYearlyReport from '../pages/SidebarPages/attendance/DashAttendanceYearlyReport.jsx';
import DashUsers from '../pages/SidebarPages/users/DashUsers.jsx';
import DashSubProjects from '../pages/SidebarPages/subProjects/DashSubProjects.jsx';
import DashProjects from '../pages/SidebarPages/projects/DashProjects.jsx';
import DashProjectAllocate from '../pages/SidebarPages/projectAllocation/DashProjectAllocate.jsx';
import DashUsersTimings from '../pages/SidebarPages/userTimings/DashUsersTimings.jsx';
import DashConfig from '../pages/SidebarPages/config/DashConfig.jsx';
import DashProfile from '../pages/SidebarPages/profile/DashProfile.jsx';
import DashModules from '../pages/SidebarPages/modules/DashModules.jsx';
import DashWorkLogReportv1 from '../pages/SidebarPages/worklog/DashWorkLogReportv1.jsx';
import DashSettings from '../pages/SidebarPages/settings/DashSettings.jsx';
import CreateAttendance from '../pages/Create/CreateAttendance.jsx';
import DashAbsentList from '../pages/SidebarPages/attendance/DashAbsentList.jsx';
import DashHolidayList from '../pages/SidebarPages/attendance/DashHolidayList.jsx';
import UpdateAbsentees from '../pages/Update/UpdateAbsentees.jsx';
import UpdateHoliday from '../pages/Update/UpdateHoliday.jsx';
import CreateDepartment from '../pages/Create/CreateDepartment.jsx';
import UpdateDepartment from '../pages/Update/UpdateDepartment.jsx';
import DashDepartment from '../pages/SidebarPages/departments/DashDepartment.jsx';
import UpdateAsset from '../pages/Update/UpdateAsset.jsx';
import CreateAsset from '../pages/Create/CreateAsset.jsx';
import DashAsset from '../pages/SidebarPages/asset/DashAsset.jsx';
import DashWFOList from '../pages/SidebarPages/attendance/DashWFOList.jsx';
import CreateWFOAttendance from '../pages/Create/CreateWFOAttendance.jsx';
import UpdateWFOAttendance from '../pages/Update/UpdateWFOAttendance.jsx';
import DashExpenses from '../pages/SidebarPages/expenses/DashExpenses.jsx';
import CreateExpenses from '../pages/Create/CreateExpenses.jsx';
import UpdateExpenses from '../pages/Update/UpdateExpenses.jsx';
import ErrorBoundry from './ErrorBoundry.jsx';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import DashRequest from '../pages/SidebarPages/Request/DashRequest.jsx';
import CreateRequest from '../pages/Create/CreateRequest.jsx';
export default function App() {
  const authUser = useAuthUser();
  const roles = authUser?.rolesType;

  return (
    <Routes>
      <Route
        path={routesnkt.home}
        element={
          <Suspense fallback={<LottieControl />}>
            <Home />
          </Suspense>
        }
      />
      <Route
        path={routesnkt.about}
        element={
          <Suspense fallback={<LottieControl />}>
            <About />
          </Suspense>
        }
      />
      <Route
        path={routesnkt.signIn}
        element={
          <Suspense fallback={<LottieControl />}>
            <SignIn />
          </Suspense>
        }
      />
      <Route path={routesnkt.signUp} element={<SignUp />} />
      <Route path={routesnkt.userVerify} element={<UserVerify />} />

      {roles && roles.includes('admin') ? (
        <Route>
          {[{ path: routesnkt.rolesv1, component: DashRolev1 }].map(
            ({ path, component, renderProps }) => (
              <Route
                key={path}
                path={path}
                element={
                  <ErrorBoundry
                    ChildComponent={component}
                    renderProps={renderProps || {}}
                  />
                }
              />
            ),
          )}
          {[
            { path: routesnkt.workLogReportv1, component: DashWorkLogReportv1 },
          ].map(({ path, component, renderProps }) => (
            <Route
              key={path}
              path={path}
              element={
                <ErrorBoundry
                  ChildComponent={component}
                  renderProps={renderProps || {}}
                />
              }
            />
          ))}

          {[{ path: routesnkt.createWorklog, component: CreateWorkLog }].map(
            ({ path, component, renderProps }) => (
              <Route
                key={path}
                path={path}
                element={
                  <ErrorBoundry
                    ChildComponent={component}
                    renderProps={renderProps || {}}
                  />
                }
              />
            ),
          )}
          {[{ path: routesnkt.updateWorklog, component: UpdateWorkLog }].map(
            ({ path, component, renderProps }) => (
              <Route
                key={path}
                path={path}
                element={
                  <ErrorBoundry
                    ChildComponent={component}
                    renderProps={renderProps || {}}
                  />
                }
              />
            ),
          )}
          {[{ path: routesnkt.modules, component: DashModules }].map(
            ({ path, component, renderProps }) => (
              <Route
                key={path}
                path={path}
                element={
                  <ErrorBoundry
                    ChildComponent={component}
                    renderProps={renderProps || {}}
                  />
                }
              />
            ),
          )}
          {[{ path: routesnkt.createModules, component: CreateModules }].map(
            ({ path, component, renderProps }) => (
              <Route
                key={path}
                path={path}
                element={
                  <ErrorBoundry
                    ChildComponent={component}
                    renderProps={renderProps || {}}
                  />
                }
              />
            ),
          )}
          {[{ path: routesnkt.updateModules, component: UpdateModules }].map(
            ({ path, component, renderProps }) => (
              <Route
                key={path}
                path={path}
                element={
                  <ErrorBoundry
                    ChildComponent={component}
                    renderProps={renderProps || {}}
                  />
                }
              />
            ),
          )}
          {[{ path: routesnkt.dashboard, component: Dashboard }].map(
            ({ path, component, renderProps }) => (
              <Route
                key={path}
                path={path}
                element={
                  <ErrorBoundry
                    ChildComponent={component}
                    renderProps={renderProps || {}}
                  />
                }
              />
            ),
          )}
          {[{ path: routesnkt.createUser, component: CreateUser }].map(
            ({ path, component, renderProps }) => (
              <Route
                key={path}
                path={path}
                element={
                  <ErrorBoundry
                    ChildComponent={component}
                    renderProps={renderProps || {}}
                  />
                }
              />
            ),
          )}
          {[{ path: routesnkt.users, component: DashUsers }].map(
            ({ path, component, renderProps }) => (
              <Route
                key={path}
                path={path}
                element={
                  <ErrorBoundry
                    ChildComponent={component}
                    renderProps={renderProps || {}}
                  />
                }
              />
            ),
          )}
          {[{ path: routesnkt.attendance, component: DashAttendance }].map(
            ({ path, component, renderProps }) => (
              <Route
                key={path}
                path={path}
                element={
                  <ErrorBoundry
                    ChildComponent={component}
                    renderProps={renderProps || {}}
                  />
                }
              />
            ),
          )}
          {[{ path: routesnkt.holidayList, component: DashHolidayList }].map(
            ({ path, component, renderProps }) => (
              <Route
                key={path}
                path={path}
                element={
                  <ErrorBoundry
                    ChildComponent={component}
                    renderProps={renderProps || {}}
                  />
                }
              />
            ),
          )}
          {[
            {
              path: routesnkt.attendanceReport,
              component: DashAttendanceReport,
            },
          ].map(({ path, component, renderProps }) => (
            <Route
              key={path}
              path={path}
              element={
                <ErrorBoundry
                  ChildComponent={component}
                  renderProps={renderProps || {}}
                />
              }
            />
          ))}
          {[
            {
              path: routesnkt.attendanceYaerlyReport,
              component: DashAttendanceYearlyReport,
            },
          ].map(({ path, component, renderProps }) => (
            <Route
              key={path}
              path={path}
              element={
                <ErrorBoundry
                  ChildComponent={component}
                  renderProps={renderProps || {}}
                />
              }
            />
          ))}
          {[{ path: routesnkt.subProjects, component: DashSubProjects }].map(
            ({ path, component, renderProps }) => (
              <Route
                key={path}
                path={path}
                element={
                  <ErrorBoundry
                    ChildComponent={component}
                    renderProps={renderProps || {}}
                  />
                }
              />
            ),
          )}
          {[{ path: routesnkt.projects, component: DashProjects }].map(
            ({ path, component, renderProps }) => (
              <Route
                key={path}
                path={path}
                element={
                  <ErrorBoundry
                    ChildComponent={component}
                    renderProps={renderProps || {}}
                  />
                }
              />
            ),
          )}
          {[{ path: routesnkt.department, component: DashDepartment }].map(
            ({ path, component, renderProps }) => (
              <Route
                key={path}
                path={path}
                element={
                  <ErrorBoundry
                    ChildComponent={component}
                    renderProps={renderProps || {}}
                  />
                }
              />
            ),
          )}
          {[
            {
              path: routesnkt.projectsAllocate,
              component: DashProjectAllocate,
            },
          ].map(({ path, component, renderProps }) => (
            <Route
              key={path}
              path={path}
              element={
                <ErrorBoundry
                  ChildComponent={component}
                  renderProps={renderProps || {}}
                />
              }
            />
          ))}
          {[{ path: routesnkt.expenses, component: DashExpenses }].map(
            ({ path, component, renderProps }) => (
              <Route
                key={path}
                path={path}
                element={
                  <ErrorBoundry
                    ChildComponent={component}
                    renderProps={renderProps || {}}
                  />
                }
              />
            ),
          )}
          {[{ path: routesnkt.createExpenses, component: CreateExpenses }].map(
            ({ path, component, renderProps }) => (
              <Route
                key={path}
                path={path}
                element={
                  <ErrorBoundry
                    ChildComponent={component}
                    renderProps={renderProps || {}}
                  />
                }
              />
            ),
          )}
          {[{ path: routesnkt.updateExpenses, component: UpdateExpenses }].map(
            ({ path, component, renderProps }) => (
              <Route
                key={path}
                path={path}
                element={
                  <ErrorBoundry
                    ChildComponent={component}
                    renderProps={renderProps || {}}
                  />
                }
              />
            ),
          )}
          {[{ path: routesnkt.asset, component: DashAsset }].map(
            ({ path, component, renderProps }) => (
              <Route
                key={path}
                path={path}
                element={
                  <ErrorBoundry
                    ChildComponent={component}
                    renderProps={renderProps || {}}
                  />
                }
              />
            ),
          )}
          {[{ path: routesnkt.createProject, component: CreateProjects }].map(
            ({ path, component, renderProps }) => (
              <Route
                key={path}
                path={path}
                element={
                  <ErrorBoundry
                    ChildComponent={component}
                    renderProps={renderProps || {}}
                  />
                }
              />
            ),
          )}
          {[{ path: routesnkt.createRoles, component: CreatRoles }].map(
            ({ path, component, renderProps }) => (
              <Route
                key={path}
                path={path}
                element={
                  <ErrorBoundry
                    ChildComponent={component}
                    renderProps={renderProps || {}}
                  />
                }
              />
            ),
          )}
          {[
            {
              path: routesnkt.createDepartment,
              component: CreateDepartment,
            },
          ].map(({ path, component, renderProps }) => (
            <Route
              key={path}
              path={path}
              element={
                <ErrorBoundry
                  ChildComponent={component}
                  renderProps={renderProps || {}}
                />
              }
            />
          ))}

          {[{ path: routesnkt.createHoliday, component: CreateHolidays }].map(
            ({ path, component, renderProps }) => (
              <Route
                key={path}
                path={path}
                element={
                  <ErrorBoundry
                    ChildComponent={component}
                    renderProps={renderProps || {}}
                  />
                }
              />
            ),
          )}
          {[
            { path: routesnkt.attendanceMark, component: CreateAttendance },
          ].map(({ path, component, renderProps }) => (
            <Route
              key={path}
              path={path}
              element={
                <ErrorBoundry
                  ChildComponent={component}
                  renderProps={renderProps || {}}
                />
              }
            />
          ))}
          {[
            {
              path: routesnkt.createSubProject,
              component: CreateSubProjects,
            },
          ].map(({ path, component, renderProps }) => (
            <Route
              key={path}
              path={path}
              element={
                <ErrorBoundry
                  ChildComponent={component}
                  renderProps={renderProps || {}}
                />
              }
            />
          ))}
          <Route path={routesnkt.updateUser} element={<UpdateUser />} />
          <Route path={routesnkt.dashboard} element={<Dashboard />} />
          <Route path={routesnkt.users} element={<DashUsers />} />
          <Route path={routesnkt.attendance} element={<DashAttendance />} />
          <Route path={routesnkt.holidayList} element={<DashHolidayList />} />

          <Route
            path={routesnkt.attendanceReport}
            element={DashAttendanceReport}
          />
          <Route
            path={routesnkt.attendanceYaerlyReport}
            element={DashAttendanceYearlyReport}
          />
          <Route path={routesnkt.subProjects} element={<DashSubProjects />} />
          <Route path={routesnkt.projects} element={<DashProjects />} />
          <Route path={routesnkt.department} element={<DashDepartment />} />

          <Route
            path={routesnkt.projectsAllocate}
            element={DashProjectAllocate}
          />
          <Route
            path={routesnkt.createWfoAttendance}
            element={<CreateWFOAttendance />}
          />
          <Route
            path={routesnkt.createProjectAssign}
            element={<CreateProjectAssign />}
          />
          <Route
            path={routesnkt.updateProjectAssign}
            element={<UpdateProjectAssign />}
          />
          <Route
            path={routesnkt.updateSubProject}
            element={<UpdateSubProjects />}
          />
          <Route path={routesnkt.updateProject} element={<UpdateProjects />} />
          <Route
            path={routesnkt.updateDepartment}
            element={<UpdateDepartment />}
          />
          <Route path={routesnkt.updateRoles} element={<UpdateRole />} />
          <Route
            path={routesnkt.updateUserTime}
            element={<UpdateUserTimings />}
          />
          <Route path={routesnkt.createConfig} element={<CreateConfig />} />
          <Route path={routesnkt.updateConfig} element={<UpdateConfig />} />
          <Route path={routesnkt.createProject} element={<CreateProjects />} />
          <Route path={routesnkt.createRoles} element={<CreatRoles />} />
          <Route
            path={routesnkt.createDepartment}
            element={<CreateDepartment />}
          />
          <Route path={routesnkt.updateAsset} element={<UpdateAsset />} />
          <Route path={routesnkt.createAsset} element={<CreateAsset />} />
          <Route path={routesnkt.clockIns} element={<DashUsersTimings />} />
          <Route
            path={routesnkt.createSubProject}
            element={CreateSubProjects}
          />
          <Route path={routesnkt.createUser} element={<CreateUser />} />
          <Route path={routesnkt.rolesv1} element={<DashRolev1 />} />
          <Route path={routesnkt.absentList} element={<DashAbsentList />} />
          <Route
            path={routesnkt.attendanceMark}
            element={<CreateAttendance />}
          />
          <Route path={routesnkt.createHoliday} element={<CreateHolidays />} />
          <Route path={routesnkt.updateHoliday} element={<UpdateHoliday />} />
          <Route path={routesnkt.wfoList} element={<DashWFOList />} />
          <Route path={routesnkt.config} element={<DashConfig />} />
          <Route path={routesnkt.profile} element={<DashProfile />} />
          <Route path={routesnkt.modules} element={<DashModules />} />
          <Route
            path={routesnkt.workLogReportv1}
            element={DashWorkLogReportv1}
          />
          <Route path={routesnkt.settings} element={<DashSettings />} />
          <Route path={routesnkt.createModules} element={<CreateModules />} />
          <Route path={routesnkt.createWorklog} element={<CreateWorkLog />} />
          <Route path={routesnkt.updateModules} element={<UpdateModules />} />
          <Route path={routesnkt.updateWorklog} element={<UpdateWorkLog />} />
          <Route
            path={routesnkt.updateabsentees}
            element={<UpdateAbsentees />}
          />
          <Route path={routesnkt.expenses} element={<DashExpenses />} />
          <Route path={routesnkt.createExpenses} element={<CreateExpenses />} />
          <Route path={routesnkt.updateExpenses} element={<UpdateExpenses />} />

          <Route
            path={routesnkt.updatesWfoattendance}
            element={<UpdateWFOAttendance />}
          />
          <Route path={routesnkt.request} element={<DashRequest />} />
          <Route path={routesnkt.createRequest} element={<CreateRequest />} />
        </Route>
      ) : roles && roles.includes('User') ? (
        <Route>
          {[{ path: routesnkt.updateAsset, component: UpdateAsset }].map(
            ({ path, component, renderProps }) => (
              <Route
                key={path}
                path={path}
                element={
                  <ErrorBoundry
                    ChildComponent={component}
                    renderProps={renderProps || {}}
                  />
                }
              />
            ),
          )}
          {[{ path: routesnkt.createAsset, component: CreateAsset }].map(
            ({ path, component, renderProps }) => (
              <Route
                key={path}
                path={path}
                element={
                  <ErrorBoundry
                    ChildComponent={component}
                    renderProps={renderProps || {}}
                  />
                }
              />
            ),
          )}
          <Route path={routesnkt.updateUser} element={<UpdateUser />} />
          <Route path={routesnkt.createModules} element={<CreateModules />} />
          <Route path={routesnkt.createWorklog} element={<CreateWorkLog />} />
          <Route path={routesnkt.updateModules} element={<UpdateModules />} />
          <Route path={routesnkt.updateWorklog} element={<UpdateWorkLog />} />
          <Route path={routesnkt.profile} element={<DashProfile />} />
          <Route path={routesnkt.modules} element={<DashModules />} />
          <Route
            path={routesnkt.workLogReportv1}
            element={<DashWorkLogReportv1 />}
          />
          <Route path={routesnkt.expenses} element={<DashExpenses />} />
          <Route path={routesnkt.createExpenses} element={<CreateExpenses />} />
          <Route path={routesnkt.updateExpenses} element={<UpdateExpenses />} />
          <Route path={routesnkt.settings} element={<DashSettings />} />
          <Route path={routesnkt.request} element={<DashRequest />} />
          <Route
            path={routesnkt.attendanceMark}
            element={<CreateAttendance />}
          />
        </Route>
      ) : (
        <Route>
          <Route
            path={routesnkt.projectsAllocate}
            element={<DashProjectAllocate />}
          />
          <Route
            path={routesnkt.createProjectAssign}
            element={<CreateProjectAssign />}
          />
          <Route
            path={routesnkt.updateProjectAssign}
            element={<UpdateProjectAssign />}
          />
          <Route
            path={routesnkt.updateSubProject}
            element={<UpdateSubProjects />}
          />
          <Route
            path={routesnkt.createSubProject}
            element={<CreateSubProjects />}
          />
          <Route path={routesnkt.subProjects} element={<DashSubProjects />} />
          <Route path={routesnkt.updateUser} element={<UpdateUser />} />
          <Route path={routesnkt.createModules} element={<CreateModules />} />
          <Route path={routesnkt.createWorklog} element={<CreateWorkLog />} />
          <Route path={routesnkt.updateModules} element={<UpdateModules />} />
          <Route path={routesnkt.updateWorklog} element={<UpdateWorkLog />} />
          <Route path={routesnkt.profile} element={<DashProfile />} />
          <Route path={routesnkt.modules} element={<DashModules />} />
          <Route
            path={routesnkt.workLogReportv1}
            element={<DashWorkLogReportv1 />}
          />
          <Route path={routesnkt.expenses} element={<DashExpenses />} />
          <Route path={routesnkt.createExpenses} element={<CreateExpenses />} />
          <Route path={routesnkt.updateExpenses} element={<UpdateExpenses />} />
          <Route path={routesnkt.settings} element={<DashSettings />} />
        </Route>
      )}

      <Route path="*" element={<h1>Not Found</h1>} />
    </Routes>
  );
}
