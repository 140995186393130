import {
  Space,
  Image,
  Button,
  Table,
  Message,
  FloatButton,
  Typography,
  RangePicker,
} from '../../../utils/antd-components/index.tsx';
import { useEffect, useState } from 'react';
import { ApiUtils } from '../../../utils/api.jsx';
import DefaultImage from '../../../assets/images/default-user.png';
import PATHS from '../../../utils/constants/Paths.tsx';
import METHOD_TYPES from '../../../utils/constants/MethodTypes.tsx';
import { useTranslation } from 'react-i18next';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { Link, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import SearchableColumn from '../../../utils/reUsable/SearchableColumn.jsx';
import routesnkt from '../../../routes/routes.json';
import {
  getStatusColumn,
  getEditColumn,
  getDeleteColumn,
} from '../../../utils/reUsable/commonColumn.jsx';

const DashModules = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authUser = useAuthUser();
  const userId = authUser._id;
  const roles = authUser.rolesType;
  const [modulesList, setModulesList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const statusFilterValue = searchParams.get('status');
    const nameFilterValue = searchParams.get('name');
    const startDateFilterValue = searchParams.get('startDate');
    const endDateFilterValue = searchParams.get('endDate');

    // Update state based on query parameters
    if (statusFilterValue && statusFilterValue !== 'all') {
      setSelectedStatus(statusFilterValue);
    } else if (statusFilterValue === 'all') {
      setSelectedStatus([]);
    }
    if (
      nameFilterValue &&
      nameFilterValue !== 'null' &&
      nameFilterValue !== 'undefined'
    ) {
      setSearchText(nameFilterValue);
    }
    if (startDateFilterValue && endDateFilterValue) {
      const dates = [dayjs(startDateFilterValue), dayjs(endDateFilterValue)];
      setDateRange(dates);
    }
    // Make API call based on query parameters
    if (userId) {
      fetchModulesList(searchParams);
    }
  }, [location.search, userId]);

  const fetchModulesList = async (searchParams) => {
    try {
      setLoading(true);
      const URL = `${PATHS.MODULES.GET}?${searchParams.toString()}`;
      const data = await ApiUtils(URL, '', METHOD_TYPES.GET);
      if (data.status === 200) {
        setModulesList(data.response);
        setTotalCount(data.totalCount);
        // setFilteredModulesList(data.response); // Update filtered modules list as well if needed
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const handleStatusFilterChange = (pagination, filters, sorter) => {
    const searchParams = new URLSearchParams(location.search);
    const statusFilterValue = filters.status && filters.status[0];

    if (filters.status && filters.status.length > 0) {
      if (statusFilterValue) {
        setSelectedStatus(statusFilterValue);
        updateURL({
          ...Object.fromEntries(searchParams),
          status: statusFilterValue,
        });
      } else {
        setSelectedStatus([]);
        updateURL({ ...Object.fromEntries(searchParams), status: 'all' });
      }
    }
    if (sorter) {
      sorter.order === 'ascend'
        ? updateURL({ ...Object.fromEntries(searchParams), sort: 'asc' })
        : updateURL({ ...Object.fromEntries(searchParams), sort: 'desc' });
    }
  };

  const handleShowMore = async (page, pageSize) => {
    try {
      setPageCount(page);
      setLoading(true);
      setPageSize(pageSize);
      const searchParams = new URLSearchParams(location.search);
      const URL = `${PATHS.MODULES.GET}?${searchParams.toString()}&page=${page}&limit=${pageSize}`;
      const data = await ApiUtils(URL, '', METHOD_TYPES.GET);
      if (data.status === 200) {
        setModulesList(data.response);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };

  const updateURL = (params) => {
    navigate(`?${new URLSearchParams(params).toString()}`); // Update URL with new query parameters
  };

  const handleDeleteUser = async (id) => {
    try {
      const payLoad = { _id: id };
      const URL = PATHS.MODULES.DELETE;
      const data = await ApiUtils(URL, payLoad, METHOD_TYPES.DELETE);
      if (data.status === 200) {
        setModulesList((prev) => prev.filter((user) => user._id !== id));
        setSuccessMessage(data.message);
      } else {
        setSuccessMessage(null);
        setErrorMessage(data.message);
        console.log('err in del catagory', data.message);
      }
    } catch (error) {
      setSuccessMessage(null);
      setErrorMessage(error.message);
      console.log('err in del catagory', error.message);
    }
  };

  const handleDateRangeChange = (dates) => {
    const searchParams = new URLSearchParams(location.search);
    if (dates && dates.length === 2) {
      const [start, end] = dates;
      setDateRange(dates);
      updateURL({
        ...Object.fromEntries(searchParams),
        startDate: dayjs(start).toISOString(),
        endDate: dayjs(end).toISOString(),
      });
    } else {
      updateURL({
        ...Object.fromEntries(searchParams),
        startDate: '',
        endDate: '',
      });
    }
  };

  const columns = [
    {
      title: t('createdOn'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => new Date(text).toLocaleDateString(),
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },
    {
      title: t('projectname'),
      dataIndex: ['projectId', 'name'],
      key: 'projectId',
      render: (text, record) => (
        <Space size="middle" align={'center'}>
          <Image
            className="w-10 h-10 object-cover bg-gray-500 rounded-full mr-4"
            width={40}
            src={record.projectId.imageUrl || DefaultImage}
            alt={record.projectId.name}
          />
          <span>{text}</span>
        </Space>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: t('module/activity'),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      ...SearchableColumn({ dataIndex: 'name', searchText }), 
    },
  ];

  if (roles.includes('admin')) {
    columns.push(
      {
        title: t('username'),
        dataIndex: ['userId', 'first_name'],
        key: 'userId',
      },
      getStatusColumn(t, selectedStatus),
    );
  }

  columns.push(getEditColumn(t, routesnkt.updatemodules));

  if (roles.includes('admin')) {
    columns.push(getDeleteColumn(t, handleDeleteUser));
  }

  return (
    <div className="w-3/4  md:mx-auto p-3">
      <FloatButton.BackTop />
      <div className="w-full flex flex-row gap-x-8 justify-between items-end my-1 py-2">
        <div className="w-1/2 flex flex-row gap-x-10 justify-between items-center my-1 ">
          <div className="w-1/2 flex flex-col gap-2 ">
            <Typography value={t('select-range')} />
            <RangePicker
              onChange={handleDateRangeChange}
              selectedValue={dateRange}
            />
          </div>
        </div>
        <div className="content-end">
          <Link to={routesnkt.createModules}>
            <Button
              type="button"
              gradientDuoTone="purpleToBlue"
              className="w-full"
            >
              {t('addModule/Activity')}
            </Button>
          </Link>
        </div>
      </div>
      <Table
        dataSource={modulesList}
        onChange={handleStatusFilterChange}
        columns={columns}
        pagination={{
          pageSize: pageSize,
          total: totalCount,
          current: pageCount,
          onChange: (page, pageSize) => {
            handleShowMore(page, pageSize);
          },
        }}
        loading={loading}
      />

      {errorMessage && <Message successMessage={errorMessage} type="error" />}
      {successMessage && (
        <Message successMessage={successMessage} type="success" />
      )}
    </div>
  );
};
export default DashModules;
