import {
  TextInput,
  Message,
  Button,
  RadioGroup,
  Typography,
  FloatButton,
  DatePicker,
} from '../../utils/antd-components/index.tsx';
import { Form } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useState, useEffect } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { useNavigate } from 'react-router-dom';
import { ApiUtils } from '../../utils/api.jsx';
import { useTranslation } from 'react-i18next';
import PATHS from '../../utils/constants/Paths.tsx';
import METHOD_TYPES from '../../utils/constants/MethodTypes.tsx';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import routesnkt from '../../routes/routes.json';
import TitleAndBackComponent from '../../utils/UiComponents/titleAndBackComponent.tsx';
import dayjs from 'dayjs';
import { Select } from 'antd';

const CreateExpenses = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const authUser = useAuthUser();
  const roles = authUser.rolesType;
  const userId = authUser._id;
  const [formData, setFormData] = useState({
    name: '',
    status: 1,
    date: dayjs().format('YYYY-MM-DD'),
  });
  const [successMessage, setsuccessMessage] = useState(null);
  const [, setProjectList] = useState(null);
  const [publishError, setPublishError] = useState(null);
  const [, setUsersList] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    try {
      const fetchUsers = async () => {
        const URL = PATHS.USER.GET + '?status=1&limit=1000';
        const data = await ApiUtils(URL);
        if (data.status !== 200) {
          console.log(data.message);
          setPublishError(data.message);
          return;
        } else {
          setPublishError(null);
          setUsersList(data.users);
        }
      };
      if (roles.includes('admin')) {
        fetchUsers();
      }
    } catch (error) {
      console.log(error.message);
    }
  }, []);
  useEffect(() => {
    const fetchProjectList = async () => {
      try {
        let URL;
        if (roles.includes('admin') && formData.userId) {
          URL = `${PATHS.EXPENSES.GET}?userId=${formData.userId}&limit=100`;
        } else if (!roles.includes('admin') && userId) {
          URL = `${PATHS.EXPENSES.GET}?userId=${userId}&limit=100`;
        }

        if (URL) {
          const data = await ApiUtils(URL);

          if (data.status !== 200) {
            console.error('Error fetching project list:', data.message);
            setPublishError(data.message);
          } else {
            setPublishError(null);
            setProjectList(data.response);
          }
        }
      } catch (error) {
        console.error('Error fetching project list:', error.message);
        setPublishError(
          'Something went wrong while fetching the project list.',
        );
      }
    };

    if (
      (!roles.includes('admin') && userId) ||
      (roles.includes('admin') && formData.userId)
    ) {
      fetchProjectList();
    }
  }, []);

  const handleSubmit = async (e) => {
    setLoading(true);
    try {
      let formattedDate = e.date ? dayjs(e.date).format('YYYY-MM-DD') : null;

      let formDataToSend = {
        ...e,
        date: formattedDate,
        userId: userId,
      };
      console.log('Form Data to Send:', formDataToSend);
      const URL = PATHS.EXPENSES.ADD;
      const data = await ApiUtils(URL, formDataToSend, METHOD_TYPES.POST);

      console.log('API Response:', data);

      if (data.status !== 200) {
        setPublishError(data.message);
        return;
      }

      setsuccessMessage(data.response.message);
      setPublishError(null);
      navigate(routesnkt.expenses);
    } catch (error) {
      setPublishError(t('something-went-wrong'));
    } finally {
      setLoading(false);
    }
  };

  const handleRadioChange = (e) => {
    const statusValue = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      status: statusValue,
    }));
  };

  return (
    <div className="p-3 max-w-3xl mx-auto min-h-screen">
      <FloatButton.BackTop />
      <TitleAndBackComponent
        title={t('AssignExpense')}
        goBackText={t('back')}
      />
      <Form initialValues={{ date: dayjs() }} onFinish={handleSubmit}>
        <div className="flex flex-row gap-4 justify-between">
          <div className="w-full flex flex-col gap-2">
            <Typography value={t('expensestype')} />
            <Form.Item
              name="expense_type"
              rules={[
                { required: true, message: t('expenses type is required') },
              ]}
            >
              <Select
                className="h-10"
                required={false}
                onChange={(value) => {
                  setFormData({ ...formData, expense_type: value });
                }}
                value={formData.expense_type}
                placeholder={t('select')}
              >
                {/* Using Select.Option to wrap your options */}
                <Select.Option value="Travel">Travel</Select.Option>
                <Select.Option value="Meals">Meals</Select.Option>
                <Select.Option value="Software">Software</Select.Option>
                <Select.Option value="Training">Training</Select.Option>
                <Select.Option value="Others">Others</Select.Option>
              </Select>
            </Form.Item>
          </div>
        </div>

        <div className="w-full flex flex-col gap-2">
          <Typography value={t('expenseTitle')} />
          <Form.Item
            name="expense_title"
            rules={[
              { required: true, message: t('expenses title is required') },
            ]}
          >
            <TextInput
              // value={formData.dept_name}
              placeholder={t('expenseTitle')}
            />
          </Form.Item>
        </div>
        <div className="flex flex-col gap-4 sm:flex-col justify-between">
          <Typography value={t('desc')} />
          <Form.Item name="description" rules={[{ required: false }]}>
            <ReactQuill
              theme="snow"
              placeholder={t('writeSomething')}
              className="h-40 mb-12"
            />
          </Form.Item>
        </div>
        <div className="w-full flex flex-col gap-2">
          <Typography value={t('amount')} />
          <Form.Item
            name="expense_amount"
            rules={[
              { required: true, message: t('expenses amount is required') },
            ]}
          >
            <TextInput
              // value={formData.dept_name}
              placeholder={t('expensesamount')}
            />
          </Form.Item>
        </div>
        <div className="w-full flex flex-col gap-2">
          <Typography value={t('select-date')} />
          <Form.Item name="date" rules={[{ required: true }]}>
            <DatePicker
              placeholder="Select Date"
              format="YYYY-MM-DD"
              required
              id="date"
              className="h-10 w-full"
              defaultValue={dayjs()}
            />
          </Form.Item>
        </div>
        <>
          {roles.includes('admin') && (
            <div className="flex flex-row gap-2 sm:flex-col justify-between">
              <Typography value={t('Status')} />
              <Form.Item name="status" rules={[{ required: true }]}>
                <RadioGroup
                  onChange={handleRadioChange}
                  selectedValue={formData.status}
                  t={t}
                />
              </Form.Item>
            </div>
          )}
        </>
        <div className="flex justify-end">
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={loading}
          >
            {t('CreateExpenses')}
          </Button>
        </div>
      </Form>
      {publishError && <Message successMessage={publishError} type="error" />}
      {successMessage && (
        <Message successMessage={successMessage} type="success" />
      )}
    </div>
  );
};

export default CreateExpenses;
