/* eslint-disable */
import { message } from 'antd';
import {
  Tag,
  Button,
  FloatButton,
  Table,
  Modal
} from '../../../utils/antd-components/index.tsx';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ApiUtils } from '../../../utils/api.jsx';
import PATHS from '../../../utils/constants/Paths.tsx';
import METHOD_TYPES from '../../../utils/constants/MethodTypes.tsx';
import { useTranslation } from 'react-i18next';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import routesnkt from '../../../routes/routes.json';
import {
  getSessionColumn,
  getStatusColumn,
  getDeleteColumn,
  getLeaveTypeColumn,
} from '../../../utils/reUsable/commonColumn.jsx';
import { CiTrash } from 'react-icons/ci';
import { EyeOutlined, CheckOutlined } from '@ant-design/icons';
import parser from 'html-react-parser';
import dayjs from 'dayjs';
import { use } from 'i18next';
const DashRequest = () => {
  const { t } = useTranslation();
  const authUser = useAuthUser();
  const userId = authUser._id;
  const navigate = useNavigate();
  const userRole = authUser.rolesType;
  const [requestList, setRequestList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [selectedLeaveType, setSelectedLeaveType] = useState([]);
  const [selectedSession, setSelectedSession] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [loadingStatusChange, setLoadingStatusChange] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const requestTypes = {
    1: t('casualleave'),
    2: t('permission'),
    3: t('sick'),
    4: t('marriage'),
    5: t('WFH'),
    6: t('maternaity'),
    7: t('parentity'),
  };
  const handleStatusChange = async (status) => {
    if (!selectedRequest) return;
    try {
      setLoadingStatusChange(true);
      const statusToSend = status === 'active' ? 2 : 1;
      const payload = {
        type: selectedRequest.type,
        userId: selectedRequest.userId?._id,
        status: statusToSend,
        startDate: selectedRequest.startDate,
        endDate: selectedRequest.endDate,
        session: selectedRequest.session,
        reason: selectedRequest.reason,
        duration: selectedRequest.duration,
      };
      const URL = `${PATHS.ATTENDANCE.UPDATE}/${selectedRequest._id}`;
      const data = await ApiUtils(URL, payload, METHOD_TYPES.PUT);
      if (data.status === 200) {
        setSuccessMessage(data.message);
        message.success(data.message);
        setIsAccepted(true);
        setIsModalVisible(false);
      } else {
        setErrorMessage(data.message);
        message.error(data.message);
      }
    } catch (error) {
      console.log('Error changing status:', error);
      setErrorMessage(t('error-pls-try-again-0'));
      message.error(t('error-pls-try-again-0'));
    } finally {
      setLoadingStatusChange(false);
    }
  };
  const handleAbsent = async (selectedRequest) => {
    try {
      const payload = {
        userId: selectedRequest.userId?._id,
        session: parseInt(selectedRequest.session),
        type: parseInt(selectedRequest.request_type),
        startDate: selectedRequest.start_date,
        endDate: selectedRequest.end_date,
        reason: selectedRequest.reason,
        time: selectedRequest.duration.dayjs().format('HH:mm'),
      };
      const URL = PATHS.ATTENDANCE.ADD;
      const data = await ApiUtils(URL, payload, METHOD_TYPES.POST);

      if (data.status === 200) {
        setLoading(true);
        return;
      } else {
        setsuccessMessage(data.message);
      }
    } catch (error) {
      console.log('error', error);
    }
  };
  useEffect(() => {
    if (userId) {
      fetchAttendanceList();
    }
  }, [userId]);
  const fetchAttendanceList = async () => {
    try {
      setLoading(true);
      const searchParams = new URLSearchParams(location.search);
      if (userRole.includes('user')) {
        searchParams.append('userId', userId);
      }
      searchParams.append('status', 'all');
      const URL =
        userRole.includes('admin')
          ? `${PATHS.ATTENDANCE.GET}?${searchParams.toString()}`
          : `${PATHS.ATTENDANCE.GET_U}?${searchParams.toString()}`;
      const data = await ApiUtils(URL, '', METHOD_TYPES.GET);
      if (data.status === 200) {
        setRequestList(data.filteredResponse);
        setTotalCount(data.filteredResponse.length);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };

  const handleStatusFilterChange = (pagination, filters) => {
    const searchParams = new URLSearchParams(location.search);
    setPageCount(pagination.current);

    if (filters.status && filters.status.length > 0) {
      searchParams.set('status', filters.status[0]);
    } else {
      searchParams.delete('status');
    }

    searchParams.set('limit', pagination.pageSize);
    searchParams.set('page', pagination.current);

    navigate(`?${searchParams.toString()}`);
  };
  const updateURL = (params) => {
    navigate(`?${new URLSearchParams(params).toString()}`);
  };
  const handleDeleteAttend = async (id) => {
    try {
      const payLoad = { _id: id };
      const URL = PATHS.ATTENDANCE.DELETE;
      const data = await ApiUtils(URL, payLoad, METHOD_TYPES.DELETE);
      if (data.status === 200) {
        fetchAttendanceList(new URLSearchParams(location.search));
        setSuccessMessage('Attendance Deleted Successfully');
      } else {
        setErrorMessage('Failed to delete attendance record');
        console.log(data.message);
      }
    } catch (error) {
      setErrorMessage('An error occurred while deleting the record');
      console.log(error.message);
    }
  };
  const columns = [
    {
      title: t('date'),
      key: 'date',
      render: (_, record) => {
        if (record.startDate && record.endDate) {
          const startDate = new Date(record.startDate).toLocaleDateString(
            'en-GB',
            {
              day: '2-digit',
              month: 'short',
              year: 'numeric',
            },
          );
          const endDate = new Date(record.endDate).toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
          });
          return `${startDate} - ${endDate}`;
        } else if (record.date) {
          return new Date(record.date).toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
          });
        } else {
          return 'N/A';
        }
      },
      sorter: (a, b) => {
        const aDate = a.startDate || a.date;
        const bDate = b.startDate || b.date;
        return new Date(aDate) - new Date(bDate);
      },
    },

    getSessionColumn(t, selectedSession),
    {
      title: t('selectduration'),
      dataIndex: 'time',
      key: 'time',
    },
    {
      title: t('userName'),
      dataIndex: ['userId', 'first_name'],
      key: 'userId',
    },
    getLeaveTypeColumn(t, selectedLeaveType),
    {
      title: t('Reason'),
      dataIndex: 'reason',
      key: 'reason',
      render: (reason) => parser(reason || ''),
    },
    {
      title: t('Status'),
      dataIndex: 'status',
      key: 'status',
            render: (status) =>
              status === 1 ? (
                <Tag active="1">{t('pending')}</Tag>
              ) : status === 2 ? (
                <Tag active="2">{t('approved')}</Tag>
              ) : (
                <Tag active="3">{t('deleted')}</Tag>
              ),
    },
    ...(userRole.includes('admin')
      ? [
          {
            title: t('Actions'),
            key: 'actions',
            render: (text, record) =>
              record.status === 2 ? (
                <CheckOutlined
                  onClick={() => {
                    setSelectedRequest(record);
                    setIsModalVisible(true);
                  }}
                  style={{ fontSize: '16px', cursor: 'pointer', color: 'blue' }}
                />
              ) : (
                <EyeOutlined
                  onClick={() => {
                    setSelectedRequest(record);
                    setIsModalVisible(true);
                  }}
                  style={{ fontSize: '16px', cursor: 'pointer', color: 'blue' }}
                />
              ),
          },
        ]
      : []),
    getDeleteColumn(t, handleDeleteAttend),
  ];
  return (
    <div className="w-3/4 table-auto overflow-x-scroll md:mx-auto p-3 scrollbar scrollbar-track-slate-100 scrollbar-thumb-slate-300 dark:scrollbar-track-slate-700 dark:scrollbar-thumb-slate-500">
      <FloatButton.BackTop />
      <div className="w-full flex flex-row justify-between my-1 py-2 mt-5">
        <Link to={routesnkt.attendanceMark}>
          <Button
            type="button"
            gradientDuoTone="purpleToBlue"
            className="w-full"
          >
            {t('addrequest')}
          </Button>
        </Link>
      </div>
      <Table
        dataSource={requestList}
        columns={columns}
        onChange={handleStatusFilterChange}
        pagination={{
          pageSize: 10,
          total: totalCount,
          current: pageCount,
        }}
        loading={loading}
      />
      <Modal
        title={
          <span
            style={{
              fontWeight: 'bold',
              fontSize: '25px',
              fontFamily: 'italic',
            }}
          >
            {t('requestdetails')}
          </span>
        }
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button
            key="accept"
            loading={loadingStatusChange}
            onClick={() => {
              handleStatusChange('active'), handleAbsent(selectedRequest);
            }}
            style={{
              backgroundColor:
                selectedRequest?.status === 2 ? 'green' : 'green',
              borderColor: selectedRequest?.status === 2 ? 'green' : 'green',
              color: 'white',
            }}
            disabled={selectedRequest?.status === 2} // Disable if already accepted
          >
            {selectedRequest?.status === 2 ? t('accepted') : t('accept')}
          </Button>,
          <Button
            key="decline"
            loading={loadingStatusChange}
            onClick={() => handleStatusChange('inactive')}
            style={{
              backgroundColor: 'red',
              borderColor: 'red',
              color: 'white',
            }}
          >
            {t('decline')}
          </Button>,
        ]}
      >
        {selectedRequest && (
          <div>
            <p>
              <strong>{t('users')}:</strong> {selectedRequest.userId.first_name}
            </p>
            <p>
              <strong>{t('requesttype')}:</strong>{' '}
              {requestTypes[selectedRequest.type] || t('Absent')}
            </p>
            <p>
              <strong> {t('startdate')}:</strong>{' '}
              {dayjs(selectedRequest.startDate).format('DD-MM-YYYY')}
            </p>
            <p>
              <strong> {t('enddate')}:</strong>{' '}
              {dayjs(selectedRequest.endDate).format('DD-MM-YYYY')}
            </p>
            <p>
              <strong> {t('Reason')}:</strong>
              {selectedRequest.reason?.replace(/<\/?p>/g, '')}{' '}
            </p>
            <p>
              <strong> {t('durations')}:</strong> {selectedRequest.time}
            </p>
            {/* <p>
              {t('Status')}: {selectedRequest.status}
            </p> */}
          </div>
        )}
      </Modal>
    </div>
  );
};

export default DashRequest;
