/* eslint-disable */
import {
  Tag,
  Table,
  Message,
  Button,
  FloatButton,
} from '../../../utils/antd-components/index.tsx';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ApiUtils } from '../../../utils/api.jsx';
import PATHS from '../../../utils/constants/Paths.tsx';
import METHOD_TYPES from '../../../utils/constants/MethodTypes.tsx';
import { useTranslation } from 'react-i18next';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import routesnkt from '../../../routes/routes.json';
import { CiEdit, CiTrash } from 'react-icons/ci';
import { AiOutlineEye } from 'react-icons/ai';
import dayjs from 'dayjs';
import Modal from 'antd/es/modal/Modal';
import SearchableColumn from '../../../utils/reUsable/SearchableColumn.jsx';

const DashExpenses = () => {
  const { t } = useTranslation();
  const authUser = useAuthUser();
  const userId = authUser._id;
  const userRole = authUser.rolesType;
  const navigate = useNavigate();
  const [expenseList, setExpenseList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [searchText, setSearchText] = useState('');
  
  const fetchExpenseList = async (searchParams) => {
    try {
      setLoading(true);
      const URL = `${PATHS.EXPENSES.GET}?${searchParams.toString()}`;
      const data = await ApiUtils(URL, '', METHOD_TYPES.GET);
      if (data.status === 200) {
        setExpenseList(data.value || data.response);
        setTotalCount(data.totalCount || 0);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrorMessage(t('error-pls-try-again-0'));
    }
  };
  
  // First useEffect: Runs when URL changes
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const expenseTitleFilterValue = searchParams.get('expense_title');
  
    if (
      expenseTitleFilterValue &&
      expenseTitleFilterValue !== 'null' &&
      expenseTitleFilterValue !== 'undefined'
    ) {
      setSearchText(expenseTitleFilterValue);
    }
  
    if (userId) {
      fetchExpenseList(searchParams);
    }
  }, [location.search, userId]);
  
  // Second useEffect: Runs initially and when userId changes
  useEffect(() => {
    if (userId) {
      const searchParams = new URLSearchParams(location.search);
      fetchExpenseList(searchParams);
    }
  }, [userId]);
  
  const handleStatusFilterChange = (pagination, filters) => {
    const statusFilterValue = filters.status && filters.status[0];
    const searchParams = new URLSearchParams(location.search);
    setPageCount(pagination.current);
    if (statusFilterValue) {
      updateURL({
        ...Object.fromEntries(searchParams),
        status: statusFilterValue,
        limit: pagination.pageSize,
        page: pagination.current,
      });
    } else {
      updateURL({
        ...Object.fromEntries(searchParams),
        status: 'all',
        limit: pagination.pageSize,
        page: pagination.current,
      });
    }
  };
  const updateURL = (params) => {
    if (searchText) {
      params.expense_title = searchText; // Add search to URL params
    } else {
      delete params.expense_title; // Remove from URL if empty
    }
    navigate(`?${new URLSearchParams(params).toString()}`);
  };
  
  const handleDeleteUser = async (id) => {
    try {
      const payLoad = { _id: id };
      const URL = PATHS.EXPENSES.DELETE;
      const data = await ApiUtils(URL, payLoad, METHOD_TYPES.DELETE);
      if (data.status === 200) {
        setExpenseList((prev) => prev.filter((user) => user._id !== id));
        setSuccessMessage(data.message);
      } else {
        setSuccessMessage(null);
        setErrorMessage(data.message);
      }
    } catch (error) {
      setSuccessMessage(null);
      setErrorMessage(t('error-pls-try-again-0'));
    }
  };
  const handleViewClick = (record) => {
    setSelectedRecord(record);
    setIsModalVisible(true);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };
  const handleAccept = async () => {
    try {
      const {
        _id,
        expenseId,
        createdAt,
        updatedAt,
        statusText,
        ...payloadWithoutId
      } = selectedRecord;
      const payload = { ...payloadWithoutId, status: 1 };

      const URL = `${PATHS.EXPENSES.UPDATE}/${_id}`;
      const response = await ApiUtils(URL, payload, METHOD_TYPES.PUT);

      if (response.status === 200) {
        setSuccessMessage(t('statusUpdatedSuccess'));
        setExpenseList((prev) =>
          prev.map((expense) =>
            expense._id === selectedRecord._id
              ? { ...expense, status: 1, statusText: t('active') }
              : expense,
          ),
        );
      } else {
        setErrorMessage(response.message);
      }
    } catch (error) {
      setErrorMessage(t('error-pls-try-again-0'));
    } finally {
      setIsModalVisible(false);
    }
  };
  const handleDecline = async () => {
    try {
      const { _id, ...payloadWithoutId } = selectedRecord;
      const payload = {
        ...payloadWithoutId,
        status: 3,
        statusText: t('inActive'),
      };
    const URL = PATHS.EXPENSES.UPDATE;
      const response = await ApiUtils(URL, payload, METHOD_TYPES.PUT);

      if (response.status === 200) {
        setSuccessMessage(t('statusUpdatedSuccess'));
        setExpenseList((prev) =>
          prev.map((expense) =>
            expense._id === selectedRecord._id
              ? { ...expense, status: 3, statusText: t('inActive') }
              : expense,
          ),
        );
      } else {
        setErrorMessage(response.message);
      }
    } catch (error) {
      setErrorMessage(t('error-pls-try-again-0'));
    } finally {
      setIsModalVisible(false);
    }
  };
  const columns = [
    {
      title: t('createdOn'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => new Date(text).toLocaleDateString(),
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },
    {
      title: t('expenseTitle'),
      dataIndex: 'expense_title',
      key: 'expense_title',
      ...SearchableColumn({ dataIndex:'expense_title', searchText }),
    },
    {
      title: t('amount'),
      dataIndex: 'expense_amount',
      key: 'expense_amount',
    },
    {
      title: t('date'),
      dataIndex: 'date',
      key: 'date',
      render: (date) => dayjs(date).format('YYYY-MM-DD'),
    },
    {
      title: t('expenseType'),
      dataIndex: 'expense_type',
      key: 'expense_type',
    }, 
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      filters: [
        { text: t('active'), value: 1 },
        { text: t('inActive'), value: 2 },
      ],
      onFilter: (value, record) => record.status === value,
      render: (status) =>
        status === 2 ? (
          <Tag active="2">{t('pending')}</Tag>
        ) : status === 1 ? (
          <Tag active="1">{t('approved')}</Tag>
        ) : (
          <Tag active="3">{t('deleted')}</Tag>
        ),
},
   
    ...(userRole.includes('admin')
      ? [
          {
            title: t('report'),
            key: 'view',
            render: (text, record) => (
              <button
                onClick={() => handleViewClick(record)}
                className="text-blue-500 hover:underline"
              >
                <AiOutlineEye size={20} color="purple" />
              </button>
            ),
          },
        ]
      : []),    
    {
      title: t('edit'),
      key: 'edit',
      render: (text, record) => (
        <Link
          to={`${'/update-expenses/'}${record._id}`}
          state={{ record }}
          className="text-teal-500 hover:underline"
        >
          <CiEdit size={20} color="blue" />
        </Link>
      ),
    },
    {
      title: t('delete'),
      key: 'delete',
      render: (text, record) => (
        <button
          onClick={() => handleDeleteUser(record._id)}
          className="text-red-500 hover:underline"
        >
          <CiTrash size={20} color="red" />
        </button>
      ),
    },
  ];
  return (
    <div className="w-3/4 table-auto overflow-x-scroll md:mx-auto p-3 scrollbar scrollbar-track-slate-100 scrollbar-thumb-slate-300 dark:scrollbar-track-slate-700 dark:scrollbar-thumb-slate-500">
      <FloatButton.BackTop />
      <div className="w-full flex flex-row justify-between my-1 py-2 mt-5">
        <Link to={routesnkt.createExpenses}>
          <Button
            type="button"
            gradientDuoTone="purpleToBlue"
            className="w-full"
          >
            {t('AssignExpense')}
          </Button>
        </Link>
      </div>
      <Table
        dataSource={expenseList}
        columns={columns}
        onChange={handleStatusFilterChange}
        pagination={{
          pageSize: 10,
          total: totalCount,
          current: pageCount,
        }}
        loading={loading}
      />
      {errorMessage && <Message successMessage={errorMessage} type="error" />}
      {successMessage && (
        <Message successMessage={successMessage} type="success" />
      )}
      <Modal
  title={t('viewExpenseDetails')}
  visible={isModalVisible}
  onCancel={handleModalCancel}
  footer={null}
>
  {selectedRecord && (
    <div>
      <p>
        <strong>{t('expenseType')}:</strong> {selectedRecord.expense_type}
      </p>
      <p>
        <strong>{t('expenseTitle')}:</strong> {selectedRecord.expense_title}
      </p>
      <p>
        <strong>{t('desc')}:</strong> {selectedRecord.description.replace(/<p>|<\/p>/g, '')}
      </p>
      <p>
        <strong>{t('amount')}:</strong> {selectedRecord.expense_amount}
      </p>
      <p>
        <strong>{t('date')}:</strong> {dayjs(selectedRecord.date).format('YYYY-MM-DD')}
      </p>
    </div>
  )}

  <div className="flex justify-end gap-2 mt-3">
    {selectedRecord && selectedRecord.status === 1 ? (
      // If selectedRecord exists and status is active, show only the Accept button
      <Button onClick={handleAccept} className="bg-green-500 text-white">
        {t('accepted')}
      </Button>
    ) : selectedRecord ? (
      // If selectedRecord exists but status is not active, show both Accept and Decline buttons
      <>
        <Button onClick={handleAccept} className="bg-green-500 text-white">
          {t('accept')}
        </Button>
        <Button onClick={handleDecline} className="bg-red-500 text-white">
          {t('decline')}
        </Button>
      </>
    ) : null}
  </div>
</Modal>

    </div>
  );
};


export default DashExpenses;
