import ReactDOM from 'react-dom/client';
import App from './App.jsx';
import './index.css';
import { store, persistor } from './redux/store.js';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import ThemeProvider from './components/ThemeProvider.tsx';
import { LanguageContextProvider } from './utils/context/LanguageContext.tsx';
import './i18n/i18n';
// eslint-disable-next-line no-unused-vars
import React from 'react';
// import * as Sentry from '@sentry/react';

// Sentry.init({
//   dsn: import.meta.env.VITE_SENTRY_DSN,
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.metrics.metricsAggregatorIntegration(),
//     Sentry.reactRouterV6BrowserTracingIntegration({
//       useEffect: React.useEffect,
//     }),
//     Sentry.replayIntegration({
//       maskAllText: false,
//       blockAllMedia: false,
//     }),
//   ],
//   environment: import.meta.env.VITE_SENTRY_ENV,
//   tracesSampleRate: 1.0,
//   tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// });
ReactDOM.createRoot(document.getElementById('root')).render(
  <PersistGate persistor={persistor}>
    <Provider store={store}>
      <ThemeProvider>
        <LanguageContextProvider>
          <App />
        </LanguageContextProvider>
      </ThemeProvider>
    </Provider>
  </PersistGate>,
);
