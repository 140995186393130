/* eslint-disable */

import React from 'react';
import * as Sentry from '@sentry/react';
import withRouter from './withRouter.jsx';
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  componentDidMount() {
    this.setState({ hasError: false });
  }

  componentDidUpdate(prevprops, prevState) {
    if (prevprops.location !== this.props.location) {
      this.setState({ hasError: false });
    }
  }

  componentDidCatch(error, errorInfo) {
    // Optionally update the state to display more details
    this.setState({ hasError: true, error, errorInfo });

    // Optional: Send error details to an external logging service
    if (this.props.logError) {
      Sentry.captureException(error, {
        extra: {
          ...errorInfo,
          route: this.props.location.pathname, // Attach current route for context
        },
      });
    }
  }

  render() {
    const { ChildComponent, location, navigate, router, renderProps } =
      this.props;

    // Check if the passed ChildComponent is a valid React component
    if (!React.isValidElement(<ChildComponent />)) {
      const errorMessage = 'Invalid component passed to ErrorBoundary.';
      return (
        <div>
          <h1>{errorMessage}</h1>
        </div>
      );
    }

    // If an error occurred, display the error details
    if (this.state.hasError) {
      const componentName = ChildComponent?.name || 'Unknown';
      const errorMessage = `omething went wrong in ${componentName}`;
      return (
        <div>
          <h1>{errorMessage}</h1>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            <summary>Click for details</summary>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo && this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }

    // Render the ChildComponent if no error occurred
    return (
      <ChildComponent
        location={location}
        navigate={navigate}
        router={router}
        {...renderProps}
      />
    );
  }
}

export default withRouter(ErrorBoundary);
